import { useState } from 'react';

import { FsImportTableAliasConfig } from 'models/ImportConfig';

import {
  Button,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';

const AliasNoteModal = ({
  isOpen,
  onClose,
  onUpdate,
  aliasRow,
}: {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (note: string | undefined) => void;
  aliasRow: FsImportTableAliasConfig | undefined;
}) => {
  const [note, setNote] = useState<string | undefined>(undefined);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Alias Note</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormLabel>
            Note
            <Textarea
              h="150px"
              placeholder="Note"
              defaultValue={aliasRow?.note}
              onChange={(v) => setNote(v.target.value)}
            />
          </FormLabel>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              onClose();
              onUpdate(note);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AliasNoteModal;

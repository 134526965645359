import { useEffect, useState } from 'react';

import Header from 'components/Header/Header';
import Login from 'components/Login/Login';
import { User } from 'firebase/auth';
import { Outlet } from 'react-router-dom';
import { auth } from 'services/firebase';

import { Container } from '@chakra-ui/react';

function App () {
  const [user, setUser] = useState<User | null>(() => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      return JSON.parse(savedUser);
    }
    return null;
  });

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        user?.getIdTokenResult().then((result) => {
          // after updating firebase from 9.15.0 to 10.11.1, although the type has changed,
          // the runtime value for result.claims.portals is still an array
          if (result.claims.portals && (result.claims.portals as string[]).includes('csa')) {
            setUser(user);
            const savedUser = JSON.stringify(user);
            localStorage.setItem('user', savedUser);
          } else {
            setUser(null);
            localStorage.removeItem('user');
          }
        });
      } else {
        setUser(null);
        localStorage.removeItem('user');
      }
    });
  }, []);

  return (
    <div className="app">
      {user ? (<>
        <Header user={user} />
        <Container minW="1600px" pt="50px">
          <Outlet />
        </Container>
      </>) : <Login />}
    </div>
  );
}

export default App;

import { FsImportColumnMapVM } from 'components/Imports/Import/MergeStrategy/ColumnMapping/types';

export const onEditStepRegex = new RegExp('^/import/[^/]*$');

export const apertureColumnMeta = [
  { targetColumn: 'stafflastname', label: 'Staff Last Name', required: true, category: 'Staff', type: 'column' },
  { targetColumn: 'stafffirstname', label: 'Staff First Name', required: true, category: 'Staff', type: 'column' },
  {
    targetColumn: 'staffemailaddress',
    label: 'Staff Email Address',
    required: true,
    category: 'Staff',
    type: 'column',
  },
  { targetColumn: 'studentid', label: 'Student ID', required: true, category: 'Student', type: 'column' },
  { targetColumn: 'lastname', label: 'Last Name', required: true, category: 'Student', type: 'column' },
  { targetColumn: 'firstname', label: 'First Name', required: true, category: 'Student', type: 'column' },
  { targetColumn: 'gender', label: 'Gender', required: true, category: 'Student', type: 'column' },
  { targetColumn: 'dob', label: 'DOB', required: true, category: 'Student', type: 'column' },
  { targetColumn: 'gradelevel', label: 'Grade Level', required: true, category: 'Student', type: 'column' },
  { targetColumn: 'sitename', label: 'Site Name', required: true, category: 'Site & Class', type: 'column' },
  { targetColumn: 'classname', label: 'Class Name', required: false, category: 'Site & Class', type: 'column' },
  { targetColumn: 'classcode', label: 'Class Code', required: false, category: 'Site & Class', type: 'column' },
  { targetColumn: 'classperiod', label: 'Class Period', required: false, category: 'Site & Class', type: 'column' },
  {
    targetColumn: 'americanindianoralaskannative',
    label: 'American Indian or Alaskan Native',
    required: false,
    category: 'Student Optional',
    type: 'column',
  },
  { targetColumn: 'asian', label: 'Asian', required: false, category: 'Student Optional', type: 'column' },
  {
    targetColumn: 'blackorafricanamerican',
    label: 'Black or African American',
    required: false,
    category: 'Student Optional',
    type: 'column',
  },
  {
    targetColumn: 'hispanicorlatinx',
    label: 'Hispanic or Latinx',
    required: false,
    category: 'Student Optional',
    type: 'column',
  },
  {
    targetColumn: 'nativehawaiianorpacificislander',
    label: 'Native Hawaiian or Pacific Islander',
    required: false,
    category: 'Student Optional',
    type: 'column',
  },
  { targetColumn: 'white', label: 'White', required: false, category: 'Student Optional', type: 'column' },
  { targetColumn: 'other', label: 'Other', required: false, category: 'Student Optional', type: 'column' },
  { targetColumn: 'specialed', label: 'Special Ed', required: false, category: 'Student Optional', type: 'column' },
  { targetColumn: 'section504', label: 'Section 504', required: false, category: 'Student Optional', type: 'column' },
  {
    targetColumn: 'englishlearner',
    label: 'English Learner',
    required: false,
    category: 'Student Optional',
    type: 'column',
  },
  {
    targetColumn: 'reducedpricemeals',
    label: 'Reduced Price Meals',
    required: false,
    category: 'Student Optional',
    type: 'column',
  },
  {
    targetColumn: 'economicallydisadvantaged',
    label: 'Economically Disadvantaged',
    required: false,
    category: 'Student Optional',
    type: 'column',
  },
  {
    targetColumn: 'mckinneyvento',
    label: 'McKinney-Vento',
    required: false,
    category: 'Student Optional',
    type: 'column',
  },
  { targetColumn: 'migrant', label: 'Migrant', required: false, category: 'Student Optional', type: 'column' },
  { targetColumn: 'gifted', label: 'Gifted', required: false, category: 'Student Optional', type: 'column' },
  { targetColumn: 'zipcode', label: 'Zip Code', required: false, category: 'Student Optional', type: 'column' },
  { targetColumn: 'stateid', label: 'State ID', required: false, category: 'Student Optional', type: 'column' },
] as FsImportColumnMapVM[];

export const wizardSteps = [
  { name: 'strategy', linkText: 'Strategy', linkTo: '' },
  { name: 'fileMap', linkText: 'File Map', linkTo: 'file-mapping' },
  { name: 'columnMap', linkText: 'Column Map', linkTo: 'column-mapping' },
  { name: 'filter', linkText: 'Filter', linkTo: 'filter' },
  { name: 'verify', linkText: 'Verify', linkTo: 'verify' },
] as Step[];

export interface Step {
  name: string;
  linkText: string;
  linkTo: string;
}

export const operators = [
  { label: 'equals', value: 'equal' },
  { label: 'does not equal', value: 'dn_equal' },
  { label: 'contains', value: 'contains' },
  { label: 'does not contain', value: 'dn_contains' },
  { label: 'in', value: 'in' },
  { label: 'in table', value: 'in_table' },
  { label: 'not in table', value: 'not_in_table' },
];

import {
  FsImportColumnMap,
  FsImportConfig,
  FsImportFileMap,
  FsImportFileMeta,
  FsImportSourceFilter,
  ImportFileStrategy,
} from '../models/ImportConfig';
import { v4 as uuidv4 } from 'uuid';

const OneRosterFileType = ['classes', 'courses', 'demographics', 'enrollments', 'orgs', 'users'] as const;

type OneRosterFile = typeof OneRosterFileType[number];

export class OneRosterUtil {
  private static mapOneRosterFiles(filesMeta: Record<string, FsImportFileMeta>): Map<OneRosterFile, FsImportFileMeta> {
    const map = new Map<OneRosterFile, FsImportFileMeta>();

    for (const [key, value] of Object.entries(filesMeta)) {
      const oneRosterFile = OneRosterFileType.find((f) => key.includes(f));
      if (oneRosterFile) map.set(oneRosterFile, value);
    }

    return map;
  }

  public static hasOneRosterFiles(importConfig: FsImportConfig | null): boolean {
    if (!importConfig?.filesMeta) return false;
    const map = this.mapOneRosterFiles(importConfig.filesMeta);
    for (const f of OneRosterFileType) {
      if (!map.has(f)) return false;
    }
    return true;
  }

  public static updateConfig(importConfig: FsImportConfig | null): Partial<FsImportConfig> | undefined {
    if (!importConfig || !this.hasOneRosterFiles(importConfig)) return;

    const group = uuidv4();
    const map = this.mapOneRosterFiles(importConfig.filesMeta ?? {});
    const config = {
      filesConfig: {
        strategy: 'join' as ImportFileStrategy,
        tables: [
          {
            alias: 'Teachers',
            sourceTable: map.get('users')?.name,
          },
          {
            alias: 'Students',
            sourceTable: map.get('users')?.name,
          },
          {
            alias: 'Teachers_Enrollments',
            sourceTable: map.get('enrollments')?.name,
          },
          {
            alias: 'Students_Enrollments',
            sourceTable: map.get('enrollments')?.name,
          },
        ],
        fileMaps: [
          {
            a: {
              column: 'sourcedid',
              file: map.get('demographics')?.name,
            },
            b: {
              column: 'sourcedid',
              file: 'Students',
            },
          },
          {
            a: {
              column: 'sourcedid',
              file: 'Students',
            },
            b: {
              column: 'usersourcedid',
              file: 'Students_Enrollments',
            },
          },
          {
            a: {
              column: 'sourcedid',
              file: 'Teachers',
            },
            b: {
              column: 'usersourcedid',
              file: 'Teachers_Enrollments',
            },
          },
          {
            a: {
              column: 'schoolsourcedid',
              file: map.get('classes')?.name,
            },
            b: {
              column: 'sourcedid',
              file: map.get('orgs')?.name,
            },
          },
          {
            a: {
              column: 'sourcedid',
              file: map.get('classes')?.name,
            },
            b: {
              column: 'classsourcedid',
              file: 'Teachers_Enrollments',
            },
          },
          {
            a: {
              column: 'coursesourcedid',
              file: map.get('classes')?.name,
            },
            b: {
              column: 'sourcedid',
              file: map.get('courses')?.name,
            },
          },
          {
            a: {
              column: 'sourcedid',
              file: map.get('classes')?.name,
            },
            b: {
              column: 'classsourcedid',
              file: 'Students_Enrollments',
            },
          },
        ] as FsImportFileMap[],
      },
      columnMaps: [
        {
          sourceColumn: 'familyname',
          sourceTable: 'Teachers',
          targetColumn: 'stafflastname',
        },
        {
          sourceColumn: 'givenname',
          sourceTable: 'Teachers',
          targetColumn: 'stafffirstname',
        },
        {
          sourceColumn: 'email',
          sourceTable: 'Teachers',
          targetColumn: 'staffemailaddress',
        },
        {
          sourceColumn: 'identifier',
          sourceTable: 'Students',
          targetColumn: 'studentid',
        },
        {
          sourceColumn: 'familyname',
          sourceTable: 'Students',
          targetColumn: 'lastname',
        },
        {
          sourceColumn: 'givenname',
          sourceTable: 'Students',
          targetColumn: 'firstname',
        },
        {
          sourceColumn: 'birthdate',
          sourceTable: map.get('demographics')?.name,
          targetColumn: 'dob',
        },
        {
          sourceColumn: 'grades',
          sourceTable: 'Students',
          targetColumn: 'gradelevel',
        },
        {
          sourceColumn: 'name',
          sourceTable: map.get('orgs')?.name,
          targetColumn: 'sitename',
        },
        {
          sourceColumn: 'title',
          sourceTable: map.get('classes')?.name,
          targetColumn: 'classname',
        },
        {
          sourceColumn: 'classcode',
          sourceTable: map.get('classes')?.name,
          targetColumn: 'classcode',
        },
        {
          sourceColumn: 'periods',
          sourceTable: map.get('classes')?.name,
          targetColumn: 'classperiod',
        },
        {
          sourceExpression:
            "CASE\nWHEN `_demographics_csv`.`americanindianoralaskanative` = 'true' THEN 'Y'\nWHEN `_demographics_csv`.`americanindianoralaskanative` = 'false' THEN 'N'\nELSE `_demographics_csv`.`americanindianoralaskanative`\nEND",
          targetColumn: 'americanindianoralaskannative',
        },
        {
          sourceExpression:
            "CASE\nWHEN `_demographics_csv`.`asian` = 'true' THEN 'Y'\nWHEN `_demographics_csv`.`asian` = 'false' THEN 'N'\nELSE `_demographics_csv`.`asian` END",
          targetColumn: 'asian',
        },
        {
          sourceExpression:
            "CASE\nWHEN `_demographics_csv`.`blackorafricanamerican` = 'true' THEN 'Y'\nWHEN `_demographics_csv`.`blackorafricanamerican` = 'false' THEN 'N'\nELSE `_demographics_csv`.`blackorafricanamerican`\nEND",
          targetColumn: 'blackorafricanamerican',
        },
        {
          sourceExpression:
            "CASE\nWHEN `_demographics_csv`.`hispanicorlatinoethnicity` = 'true' THEN 'Y'\nWHEN `_demographics_csv`.`hispanicorlatinoethnicity` = 'false' THEN 'N'\nELSE `_demographics_csv`.`hispanicorlatinoethnicity`\nEND",
          targetColumn: 'hispanicorlatinx',
        },
        {
          sourceExpression:
            "CASE\nWHEN `_demographics_csv`.`nativehawaiianorotherpacificislander` = 'true' THEN 'Y'\nWHEN `_demographics_csv`.`nativehawaiianorotherpacificislander` = 'false' THEN 'N'\nELSE `_demographics_csv`.`nativehawaiianorotherpacificislander`\nEND",
          targetColumn: 'nativehawaiianorpacificislander',
        },
        {
          sourceExpression:
            "CASE\nWHEN `_demographics_csv`.`white` = 'true' THEN 'Y'\nWHEN `_demographics_csv`.`white` = 'false' THEN 'N'\nELSE `_demographics_csv`.`white`\nEND",
          targetColumn: 'white',
        },
        {
          sourceExpression:
            "CASE\nWHEN `_demographics_csv`.`demographicracetwoormoreraces` = 'true' THEN 'Y'\nWHEN `_demographics_csv`.`demographicracetwoormoreraces` = 'false' THEN 'N'\nELSE `_demographics_csv`.`demographicracetwoormoreraces`\nEND",
          targetColumn: 'other',
        },
        {
          sourceExpression:
            "CASE\nWHEN `_demographics_csv`.`sex` = 'female' THEN 'F'\nWHEN `_demographics_csv`.`sex` = 'male' THEN 'M'\nELSE `_demographics_csv`.`sex`\nEND",
          targetColumn: 'gender',
        },
      ] as FsImportColumnMap[],
      sourceFilters: [
        {
          column: 'role',
          group,
          operator: 'equal',
          table: 'Teachers',
          value: 'teacher',
        },
        {
          column: 'role',
          group,
          operator: 'equal',
          table: 'Teachers_Enrollments',
          value: 'teacher',
        },
        {
          column: 'role',
          group,
          operator: 'equal',
          table: 'Students',
          value: 'student',
        },
        {
          column: 'role',
          group,
          operator: 'equal',
          table: 'Students_Enrollments',
          value: 'student',
        },
      ] as FsImportSourceFilter[],
    };

    const demo = map.get('demographics')?.name ?? 'replace_with_table_name';
    for (const col of config.columnMaps) {
      if (col.sourceExpression) {
        col.sourceExpression = col.sourceExpression.replaceAll('_demographics_csv', demo);
      }
    }

    return config;
  }
}

import { Timestamp } from 'firebase/firestore';

export const frequencyTypes = ['WEEKDAYS', 'WEEKLY', 'MONTHLY'] as const;

export const exportTypes = [
  { label: 'Rating Export', value: 'rating_export' },
  { label: 'CT Rating Export', value: 'ct_rating_export' },
  { label: 'NY Rating Export', value: 'ny_rating_export' },
  { label: 'Support Strategy Export', value: 'support_strategy_export' },
];

export interface ExportConfig {
  id: string;
  org: Org;
  export: Export;
  frequency: Frequency;
  file: File;
  pausedAt: Timestamp | undefined;
  note: string | undefined;
  lastSuccessfulRunAt: Timestamp | undefined;
  lastRun: LastRun;
  adhocSuccessfulRunAt: Timestamp | undefined;
  adhocRun: LastRun;
}

export interface AdhocExportConfig extends ExportConfig {
  dateRange: {
    begin: string | undefined;
    end: string | undefined;
  }
}

export type FrequencyType = typeof frequencyTypes[number];

export interface Org {
  id: string;
  name: string;
  hubSpotId: string;
  hubspotId?: string;
  licenseStart: Date;
  licenseEnd: Date;
}

export interface Export {
  type: string;
  programIds: string[] | undefined;
  assessmentTypeIds: string[] | undefined;
}

export interface Frequency {
  type: FrequencyType;
  dayOfWeek: number | undefined;
  dayOfMonth: number | undefined;
}

export interface File {
  name: string | undefined;
  delimiter: string;
  directory: string;
}

export interface LastRun {
  createdBy: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  status: string;
  error: string | undefined;
}

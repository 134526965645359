import { useEffect, useState } from 'react';

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { FsImportConfig, FsImportFileMap } from 'models/ImportConfig';
import { useParams } from 'react-router-dom';
import { db } from 'services/firebase';
import { getColumnsForFile, getFileList } from 'services/utils';

import { AddIcon, DeleteIcon, InfoIcon, LinkIcon } from '@chakra-ui/icons';
import { Box, Button, Card, CardBody, Center, Flex, Heading, IconButton, Spacer, Text } from '@chakra-ui/react';

import ColumnDropdown from '../ColumnDropdown/ColumnDropdown';
import FileDropDown from '../FileDropdown/FileDropdown';
import WizardFooter from '../WizardFooter/WizardFooter';
import styles from './FileMapping.module.scss';

const columnWidth = '450px';

const FileMapping = () => {
  const { importId } = useParams();
  const [importConfig, setImportConfig] = useState<FsImportConfig | null>(null);
  const [fileMappings, setFileMappings] = useState<FsImportFileMap[]>([]);

  useEffect(() => {
    getDoc(doc(db, `/importConfigs/${importId}`)).then((querySnapshot) => {
      const data = querySnapshot.data() as FsImportConfig;
      setImportConfig(data);
      setFileMappings(data?.filesConfig?.fileMaps || []);
    });
  }, [importId]);

  const addFileMapRow = () => {
    setFileMappings([...fileMappings, { a: { file: '', column: '' }, b: { file: '', column: '' } }]);
  };

  const removeFileMapRow = (index: number) => {
    const newFileMappings = [...fileMappings];
    newFileMappings.splice(index, 1);
    setFileMappings(newFileMappings);
    save(newFileMappings);
  };

  const editRow = (editIndex: number, ab: 'a' | 'b', field: string, value: string) => {
    const newFileMappings = fileMappings.map((fileMap, index) => {
      if (index === editIndex) {
        if (field === 'file') {
          return { ...fileMap, [ab]: { ...fileMap[ab], [field]: value, 'column': '' } };
        }
        return { ...fileMap, [ab]: { ...fileMap[ab], [field]: value } };
      } else {
        return fileMap;
      }
    });
    setFileMappings(newFileMappings);
    save(newFileMappings);
  };

  const save = async (newFileMappings?: FsImportFileMap[]) => {
    if (importConfig?.filesConfig?.strategy === 'stack') {
      return Promise.resolve();
    } else {
      const newConfig = { ...importConfig?.filesConfig, fileMaps: newFileMappings ?? fileMappings };
      await updateDoc(doc(db, `/importConfigs/${importId}`), { filesConfig: newConfig });
    }
  };

  return <Box>
    {importConfig?.filesConfig?.strategy === 'stack' ? <Center h='300px'>
      <Card colorScheme='cyan' variant='filled'>
        <CardBody>
          <Flex>
            <Center><InfoIcon boxSize={5} aria-label='Stack skip step info' /></Center>
            <Text pl='15px'>Your file merge strategy is stacked, therefore this step is not required and you can move onto the column mapping step.</Text>
          </Flex>
        </CardBody>
      </Card>
    </Center> : <>
      <Heading as='h2' size="md">File Map</Heading>
      <Text fontSize='sm'>How should the rows in each file be joined together?</Text>
      <Box className={styles.main_body}>
        <Flex pt='50px' direction='column'>
          <Flex direction='row' pb='15px'>
            <Box w={columnWidth} pr='15px'><Text fontWeight='bold'>File</Text></Box>
            <Box w={columnWidth} pr='15px'><Text fontWeight='bold'>Column</Text></Box>
            <Box w='100px'><Spacer /></Box>
            <Box w={columnWidth} pr='15px'><Text fontWeight='bold'>File</Text></Box>
            <Box w={columnWidth} pr='15px'><Text fontWeight='bold'>Column</Text></Box>
            <Box w='100px'><Spacer /></Box>
          </Flex>
          {fileMappings.map((fileMap, index) => {
            return <Flex direction='row' key={`${index + fileMap.a.file + fileMap.a.column + fileMap.b.file + fileMap.b.column}`} pb='15px'>
              <Box w={columnWidth} pr='15px'><FileDropDown value={fileMap.a.file} fileList={getFileList(importConfig)} onChange={(v: string) => { editRow(index, 'a', 'file', v); }}></FileDropDown></Box>
              <Box w={columnWidth} pr='15px'><ColumnDropdown value={fileMap.a.column} columnNames={getColumnsForFile(importConfig, false, fileMap.a.file)} onChange={(v: string) => { editRow(index, 'a', 'column', v); }} /></Box>
              <Box w='100px'><LinkIcon></LinkIcon></Box>
              <Box w={columnWidth} pr='15px'><FileDropDown value={fileMap.b.file} fileList={getFileList(importConfig)} onChange={(v: string) => { editRow(index, 'b', 'file', v); }}></FileDropDown></Box>
              <Box w={columnWidth} pr='15px'><ColumnDropdown value={fileMap.b.column} columnNames={getColumnsForFile(importConfig, false, fileMap.b.file)} onChange={(v: string) => { editRow(index, 'b', 'column', v); }} /></Box>
              <Box w='100px'><IconButton variant='ghost' onClick={() => { removeFileMapRow(index); }} icon={<DeleteIcon />} aria-label={'Remove File Mapp Row'}></IconButton></Box>
            </Flex>;
          })}
          <Spacer />
        </Flex>
        <Button mt='25px' leftIcon={<AddIcon />} variant='ghost' onClick={addFileMapRow} aria-label='Add File Map'> Add File Map</Button>
      </Box>
    </>}
    <WizardFooter save={save} />
  </Box>;
};

export default FileMapping;
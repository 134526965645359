/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { FirebaseError } from 'firebase/app';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { FsImportConfig } from 'models/ImportConfig';
import { useParams } from 'react-router-dom';
import { auth, db } from 'services/firebase';
import { convertToLocalDayAndTime } from 'services/utils';

import { WarningIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Heading,
  Progress,
  Spacer,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { apertureColumnMeta } from '../../../../../constants';
import styles from './Verify.module.scss';
import axios from 'axios';

const Verify = () => {
  const { importId } = useParams();
  const [importConfig, setImportConfig] = useState<FsImportConfig | null>(null);
  const [loadingQuery, setLoadingQuery] = useState<boolean>(false);
  const [loadingSample, setLoadingSample] = useState<boolean>(false);
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [queryResults, setQueryResults] = useState<any[] | undefined>(undefined);
  const [progressData, setProgressData] = useState<{ percent: number; message: string }>({
    percent: 0,
    message: 'Import has not been run yet',
  });
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [importErrorMessage, setImportErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    getDoc(doc(db, `/importConfigs/${importId}`)).then((querySnapshot) => {
      const data = querySnapshot.data() as FsImportConfig;
      setImportConfig(data);
    });

    onSnapshot(doc(db, `/importConfigs/${importId}`), (querySnapshot) => {
      const data = querySnapshot.data() as FsImportConfig;
      setImportConfig(data);
      getProgress(data);
    });
  }, [importId]);

  const build = async () => {
    setLoadingQuery(true);
    setErrorMessage('');
    try {
      const token = await auth.currentUser?.getIdToken();
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}csa/import/${importId}/query`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setQuery(data as string);
      setLoadingQuery(false);
    } catch (e: unknown) {
      const error = (e as FirebaseError).message;
      setErrorMessage(error);
      setLoadingQuery(false);
    }
  };

  const sample = async () => {
    setLoadingSample(true);
    setErrorMessage('');
    try {
      const token = await auth.currentUser?.getIdToken();
      const { data } = await axios.get(
        `${process.env.REACT_APP_JOBS_BASE_URL}csa/import/${importId}/sample?rowCount=100`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      setQuery(data.query as string);
      setQueryResults(data.results);
      setLoadingSample(false);
    } catch (e: unknown) {
      const error = (e as FirebaseError).message;
      setErrorMessage(error);
      setLoadingSample(false);
    }
  };

  const executeImport = async () => {
    setLoadingImport(true);
    setImportErrorMessage(undefined);
    try {
      const token = await auth.currentUser?.getIdToken();
      await axios.post(`${process.env.REACT_APP_JOBS_BASE_URL}csa/import/${importId}/execute`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (e: unknown) {
      const error = (e as FirebaseError).message;
      setImportErrorMessage(error);
    }
  };

  const getProgress = (data: FsImportConfig) => {
    switch (data?.lastRun?.status) {
      case 'BUILDING':
        setProgressData({ percent: 20, message: 'Generating Import File' });
        setLoadingImport(true);
        break;
      case 'PROCESSING':
        setProgressData({ percent: 80, message: 'Processing Import File' });
        setLoadingImport(true);
        break;
      case 'COMPLETED':
        setProgressData({ percent: 100, message: 'Completed Processing' });
        setLoadingImport(false);
        break;
      case 'FAILED':
        setProgressData({ percent: 0, message: 'Failed Processing Import' });
        setLoadingImport(false);
        break;
      default:
        setProgressData({ percent: 0, message: 'Import has not been run yet' });
        setLoadingImport(false);
        break;
    }
  };
  let processingTime = 0;
  if (importConfig?.lastRun?.updatedAt) {
    processingTime = new Date().getTime() / 1000 - importConfig?.lastRun?.updatedAt.seconds;
  }
  const importDisabled = loadingQuery || loadingSample || (loadingImport && processingTime < 3600);
  return (
    <>
      <Flex direction="row">
        <Heading as="h2" size="md" mb="25px">
          Verify
        </Heading>
      </Flex>
      <Box className={styles.main_body} mb="50px" pr="15px">
        <Box pb="25px">
          {Boolean(errorMessage) && (
            <Card p="10px" mb="25px" variant="filled">
              <Flex>
                <Center>
                  <WarningIcon boxSize={5} aria-label="Validation Errors" />
                </Center>
                <Text pl="15px">{errorMessage}</Text>
              </Flex>
            </Card>
          )}
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
              <AccordionButton>
                <Text fontSize="20px" fontWeight="bold">
                  Query
                </Text>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Flex justifyContent="end">
                  <Button onClick={build} disabled={loadingQuery || loadingSample || loadingImport}>
                    Build
                  </Button>
                </Flex>
                {query ? (
                  <Card p="15px" variant="outline" mt="25px" maxH="300px" overflowY="scroll">
                    {loadingQuery ? (
                      <Center h="200px">
                        <Spinner />
                      </Center>
                    ) : (
                      <Text className={styles.display_linebreak}>{query}</Text>
                    )}
                  </Card>
                ) : (
                  <Text>Click Build to see the generated query</Text>
                )}
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Text fontSize="20px" fontWeight="bold">
                  Query Results
                </Text>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Flex justifyContent="end">
                  <Button onClick={sample} disabled={loadingQuery || loadingSample || loadingImport}>
                    Sample
                  </Button>
                </Flex>
                <Box>
                  {!loadingSample ? (
                    !queryResults ? (
                      <Text>Click Sample to see the query results</Text>
                    ) : (
                      <TableContainer maxH="300px" overflowY="scroll">
                        <Table variant="simple">
                          <Thead>
                            <Tr>
                              {apertureColumnMeta.map((column, index) => (
                                <Th key={index}>{column.label}</Th>
                              ))}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {queryResults?.length === 0 ? (
                              <Tr>There were no results returned for this query.</Tr>
                            ) : null}
                            {queryResults?.map((data: any, index) => {
                              return (
                                <Tr key={index}>
                                  {apertureColumnMeta.map((columnMeta, yIndex) => (
                                    <Td key={yIndex}>{data[columnMeta.targetColumn]}</Td>
                                  ))}
                                </Tr>
                              );
                            })}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    )
                  ) : (
                    <Center h="200px">
                      <Spinner />
                    </Center>
                  )}
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Text fontSize="20px" fontWeight="bold">
                  Import
                </Text>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Flex>
                  <Box minW="50%">
                    <Progress value={progressData.percent} />
                    <Text mb="10px">{progressData.message}</Text>
                    <Text>
                      Last Run at:{' '}
                      {importConfig?.lastRun ? convertToLocalDayAndTime(importConfig?.lastRun?.updatedAt) : 'NA'}
                    </Text>
                  </Box>
                  <Spacer />
                  <Box>
                    <Button onClick={executeImport} disabled={importDisabled}>
                      Import
                    </Button>
                  </Box>
                </Flex>
                {importConfig?.lastRun?.error ? (
                  <Card p="10px" mt="25px" mb="25px" variant="filled">
                    <Flex>
                      <Center>
                        <WarningIcon boxSize={5} aria-label="Import Errors" />
                      </Center>
                      <Text pl="15px">{importConfig.lastRun.error.toString()}</Text>
                    </Flex>
                  </Card>
                ) : null}
                {Boolean(importErrorMessage) && (
                  <Card p="10px" mt="25px" mb="25px" variant="filled">
                    <Flex>
                      <Center>
                        <WarningIcon boxSize={5} aria-label="Import Errors" />
                      </Center>
                      <Text pl="15px">{importErrorMessage}</Text>
                    </Flex>
                  </Card>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </>
  );
};

export default Verify;

import React from 'react';
import { Formik, Form } from 'formik';
import { Button, FormControl, FormHelperText } from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import { auth } from 'services/firebase';
import { getErrorMessages } from 'services/utils';
import { StyledFileSelectField } from './StyledFileSelectField';
import { MoveRatingSites, ValidationSchema } from 'models/ChangeRatingSite';
import * as Yup from 'yup';

type RunSearchProps = {
  programId: string;
  setProgramId: (value: string) => void;
  file: File | undefined;
  setFile: (file: File | undefined) => void;
  setSearchResult: (result: MoveRatingSites | undefined) => void;
  setError: (error: string) => void;
  setIsSearching: (isSearching: boolean) => void;
  validationSchema: Yup.ObjectSchema<ValidationSchema>;

  isSearching: boolean;
};

export const SearchRatingSite: React.FC<RunSearchProps> = ({
  programId,
  file,
  setFile,
  setSearchResult,
  setError,
  setIsSearching,
  validationSchema,
  isSearching,
}) => {
  const runSearch = async (values: { file: File | undefined }) => {
    try {
      setError('');
      setSearchResult(undefined);
      const formData = new FormData();
      if (values.file) {
        formData.append('file', values.file);
      }
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        setIsSearching(true);
        const res = await axios.post(`${process.env.REACT_APP_JOBS_BASE_URL}csa/admin/find/ratingsite`, formData, {
          headers: { Authorization: `Bearer ${token}` },
          params: { pid: programId },
        });
        setSearchResult(res.data);
        setFile(values.file);
        setIsSearching(false);
      }
    } catch (error) {
      const e = error as AxiosError;
      setError(getErrorMessages(e));
      setIsSearching(false);
    }
  };

  return (
    <Formik initialValues={{ file: file }} validationSchema={validationSchema} onSubmit={runSearch}>
      <Form>
        <FormControl>
          <FormHelperText>
            Select a CSV file with one header, &apos;rating_id&apos;, and the ratings IDs you&apos;d like to transfer
          </FormHelperText>
        </FormControl>
        <StyledFileSelectField name="file" />
        <Button colorScheme="blue" type="submit" isLoading={isSearching}>
          Upload CSV
        </Button>
      </Form>
    </Formik>
  );
};

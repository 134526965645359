import { useEffect, useState } from 'react';

import { auth } from 'services/firebase';

import {
  Box,
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import axios from 'axios';

const DistinctValuesModal = ({
  isOpen,
  onClose,
  table,
  column,
  dataHeader,
}: {
  isOpen: boolean;
  onClose: () => void;
  table: string | undefined;
  column: string | undefined;
  dataHeader: string | undefined;
}) => {
  const [distinctValues, setDistinctValues] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    if (table && dataHeader) {
      auth.currentUser?.getIdToken().then((token) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}csa/import/sample/table/${table}/column/${dataHeader}?rowCount=10`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((data) => {
            setDistinctValues(data.data);
            setLoading(false);
          });
      });
    }
  }, [table, dataHeader]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Distinct Values</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="sm" pb="15px">
            {table}
            {column ? '.' : ''}
            {column}
          </Heading>
          {loading ? (
            <Center h="100px">
              <Spinner />
            </Center>
          ) : (
            <Box maxH="400px" overflowY="scroll">
              <TableContainer>
                <Table variant="simple" size="sm">
                  <Tbody>
                    {distinctValues.map((x, index) => (
                      <Tr key={index}>
                        <Td>{x}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DistinctValuesModal;

import { FormControl, FormLabel, FormErrorMessage, Text, Button, HStack } from '@chakra-ui/react';
import { ReactNode, useRef } from 'react';
import { Field, FieldProps } from 'formik';

export interface StyledFileSelectFieldProps {
  name: string;
  label?: string;
  noErrorLabel?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}
export const StyledFileSelectField: React.FC<StyledFileSelectFieldProps> = ({
  name,
  label,
  noErrorLabel = false,
  disabled,
}: StyledFileSelectFieldProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Field name={name}>
      {({ form }: FieldProps) => (
        <FormControl
          marginRight="2"
          _invalid={{ textColor: 'danger.500' }}
          isInvalid={!!(form.errors[name] && form.touched[name])}
        >
          {label && (
            <FormLabel _invalid={{ textColor: 'danger.500' }} fontSize="xl" fontWeight="semibold" htmlFor={name} mb={2}>
              {label}
            </FormLabel>
          )}
          <HStack
            alignItems="center"
            justify="space-between"
            borderWidth="1px"
            borderColor="input.borderColor"
            borderRadius="md"
            p={2}
            spacing={1}
          >
            <input
              type="file"
              accept={'text/csv, application/csv, .csv'}
              multiple={false}
              ref={inputRef}
              style={{ display: 'none' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const files: FileList | null = e.target.files;
                if (files) {
                  form.setFieldValue(name, files[0]);
                  form.setFieldTouched(name, true, false);
                }
              }}
            />
            <Text fontWeight="light" fontSize="lg" overflow="hidden" whiteSpace="break-spaces">
              {form.values[name]?.name || 'Select file...'}
            </Text>
            <Button
              bg="button.browse.bg"
              color="label.color"
              size="md"
              onClick={() => inputRef.current?.click()}
              w="116px"
              h="36px"
              isDisabled={disabled}
            >
              Browse
            </Button>
          </HStack>
          {!noErrorLabel &&
            (form.errors[name] && form.touched[name] ? (
              <FormErrorMessage color="danger.500" fontSize="xs">
                {form.errors[name] as unknown as ReactNode}
              </FormErrorMessage>
            ) : (
              <Text height="26px">{` `}</Text>
            ))}
        </FormControl>
      )}
    </Field>
  );
};

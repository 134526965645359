import { useEffect, useState } from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Divider, Flex, Link, Text } from '@chakra-ui/react';

import { Step, wizardSteps } from '../../../../../constants';
import styles from './WizardHeader.module.scss';

const WizardHeader = () => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<string>(wizardSteps[0].name);
  useEffect(() => {
    if (location.pathname.includes(wizardSteps[1].linkTo)) {
      setCurrentStep(wizardSteps[1].name);
    } else if (location.pathname.includes(wizardSteps[2].linkTo)) {
      setCurrentStep(wizardSteps[2].name);
    } else if (location.pathname.includes(wizardSteps[3].linkTo)) {
      setCurrentStep(wizardSteps[3].name);
    } else if (location.pathname.includes(wizardSteps[4].linkTo)) {
      setCurrentStep(wizardSteps[4].name);
    } else {
      setCurrentStep(wizardSteps[0].name);
    }
  }, [location]);

  const buildLink = (step: Step, index: number) => {
    return <Link key={index} as={RouterLink} to={step.linkTo} className={`${styles.wizardLink} ${currentStep === step.name ? styles.active : ''}`}>
      <Text fontSize='1.25rem'>{step.linkText}</Text> {currentStep === step.name ? <Divider size='lg' borderColor='blue.500' /> : <></>}
    </Link>;
  };

  return <Flex pb='25px' pl='25px' pr='25px' justifyContent='space-between'>
    {wizardSteps.map((x, index) => buildLink(x, index))}
  </Flex>;
};

export default WizardHeader;
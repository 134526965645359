import { ReactNode } from 'react';

import logo from 'assets/image.png';
import { useNavigate } from 'react-router-dom';
import { auth } from 'services/firebase';

import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { User } from '@firebase/auth';

import styles from './Header.module.scss';

const Links = [
  { name: 'Imports', url: '' },
  { name: 'Exports', url: 'exports' },
];

const NavLink = ({
  children,
  url,
  navigate,
}: {
  children: ReactNode;
  url: string;
  navigate: (url: string) => void;
}) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    onClick={() => {
      navigate(`/${url}`);
    }}
  >
    {children}
  </Link>
);

const Header = ({ user }: { user: User }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} minW="1600px">
        <Container minW="1600px">
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack spacing={8} alignItems={'center'}>
              <Box>
                <img src={logo} alt="logo" className={styles.logo} />
              </Box>
              <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                {Links.map((link) => (
                  <NavLink key={link.name} url={link.url} navigate={navigate}>
                    {link.name}
                  </NavLink>
                ))}
              </HStack>
              <HStack as={'nav'} display={{ base: 'none', md: 'flex' }}>
                <Menu>
                  <MenuButton>Data Tools</MenuButton>
                  <MenuList>
                    <MenuItem as="a" href="/ratingstudentid">
                      Change Rating Student ID
                    </MenuItem>
                    <MenuItem as="a" href="/rateremail">
                      Change Rater Email
                    </MenuItem>
                    <MenuItem as="a" href="/ratingsite">
                      Change Rating Site
                    </MenuItem>
                    <MenuItem as="a" href="/ratingassignmentperiod">
                      Change Rating Assignment Period
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </HStack>
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton as={Button} rounded={'full'} variant={'link'} cursor={'pointer'} minW={0}>
                  <Avatar size={'sm'} src={user.photoURL || undefined} />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => auth.signOut()}>Sign out</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
        </Container>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link.name} url={link.url} navigate={navigate}>
                  {link.name}
                </NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default Header;

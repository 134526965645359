import { useState } from 'react';
import axios from 'axios';
import { ExportConfig } from 'models/ExportConfig';
import { auth } from 'services/firebase';
import { FormControl, FormErrorMessage, FormHelperText } from '@chakra-ui/react';

import {
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from '@chakra-ui/react';


export const RunAdhocModal : React.FC <{
  isOpen: boolean;
  onClose: () => void;
  exportConfig? : ExportConfig 

}> = ({
  isOpen,
  onClose,
  exportConfig,
})=> {
   
  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFilename] = useState<string | undefined>(exportConfig ? exportConfig.file.name : undefined);
  const [directory, setDirectory] = useState<string | undefined>(
    exportConfig ? exportConfig.file.directory : undefined,
  );
  const [dateBegin, setDateBegin] = useState<string | undefined>()
  const [dateEnd, setDateEnd] = useState<string | undefined>()
  const [error, setError] = useState('');

  const handleClose = () => {
    onClose();
  };

const runAdhoc = async () => {
    try {
      setError('');
      if (!dateBegin || !dateEnd || !exportConfig?.file.directory) {
        setError('A required field is missing');
        return;
      }
  
      const token = await auth.currentUser?.getIdToken();
      if (exportConfig && token) {
        setIsLoading(true);
        await axios.post(`${process.env.REACT_APP_JOBS_BASE_URL}csa/export/adhoc`, {
          dateRange: {
            begin: dateBegin,
            end: dateEnd,
          },
          id: exportConfig.id,
          export: exportConfig.export,
          file: {
            delimiter: exportConfig.file.delimiter,
            directory: exportConfig.file.directory,
            name: fileName,
          },
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        setIsLoading(false);
        onClose();
      }
    } catch (error) {
      setError('An error occurred while processing the request. Please try again.');
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Run Adhoc Export</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired w="100%" marginTop="15px" marginBottom="15px">
            <FormLabel>Directory</FormLabel>
            <Input
              name="directory"
              placeholder=""
              defaultValue={directory}
              onChange={(v) => setDirectory(v.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>File Name</FormLabel>
            <Input defaultValue={fileName} onChange={(v) => setFilename(v.target.value)} />
            <FormHelperText>Include an extension.</FormHelperText>
          </FormControl>
          <SimpleGrid columns={2} spacing={5}>
            <FormControl isRequired w="100%" marginTop="15px" marginBottom="15px">
              <FormLabel>Start Date</FormLabel>
              <Input 
                type='date'
                defaultValue={dateBegin}
                onChange={(v) => setDateBegin(v.target.value)}
            />
          </FormControl>
          <FormControl isRequired w="100%" marginTop="15px" marginBottom="15px">
            <FormLabel>End Date</FormLabel>
            <Input 
            type='date'
            defaultValue={dateEnd}
            onChange={(v) => setDateEnd(v.target.value)}
            />
          </FormControl>
          </SimpleGrid>
          <FormControl isInvalid={!!error}>
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
            
          <Button variant="ghost" mr={3} onClick={handleClose}>
            Close
          </Button>
          <Button
            isLoading = {isLoading}
            colorScheme="blue"
            onClick={() => {
              runAdhoc();
            }}
          >
            Run Adhoc Export
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}


import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  VStack,
  Input,
  Alert,
  AlertIcon,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Flex,
  StackDivider,
  Container,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import React from 'react';
import { SetStateAction, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { auth } from '../../services/firebase';
import { getErrorMessages, utcToLocalDayAndTime } from '../../services/utils';
import { MoveRaterEmailRating, MoveRaterEmailRatingCount } from '../../models/ChangeRaterEmail';

export const ChangeRaterEmail: React.FC = () => {
  const [inputOldEmail, setInputOldEmail] = useState('');
  const handleInputChangeOldEmail = (e: { target: { value: SetStateAction<string> } }) =>
    setInputOldEmail(e.target.value);
  const isOldEmailError = inputOldEmail === '';

  const [inputNewEmail, setInputNewEmail] = useState('');
  const handleInputChangeNewEmail = (e: { target: { value: SetStateAction<string> } }) =>
    setInputNewEmail(e.target.value);
  const isNewEmailError = inputNewEmail === '';

  const [error, setError] = useState<string | undefined>(undefined);
  const [ratingsList, setRatings] = useState<MoveRaterEmailRating[] | undefined>(undefined);
  const [isSearching, setIsSearching] = useState(false);
  const runSearch = async () => {
    try {
      setError(undefined);
      setRatings(undefined);
      setTransfer(undefined);
      if (!inputOldEmail || !inputNewEmail) {
        setError('A required field is missing');
        return;
      }

      const token = await auth.currentUser?.getIdToken();
      if (token) {
        setIsSearching(true);
        const res = await axios.post(
          `${process.env.REACT_APP_JOBS_BASE_URL}csa/admin/rateremail`,
          {
            oldRaterEmail: inputOldEmail,
            newRaterEmail: inputNewEmail,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setRatings(res.data);
        setIsSearching(false);
      }
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.status === 404) setError('New Rater Email was not rostered');
      else setError(getErrorMessages(e));
      setIsSearching(false);
      console.error(error);
    }
  };

  const [isTransfer, setIsTransfer] = useState(false);
  const [transfer, setTransfer] = useState<MoveRaterEmailRatingCount | undefined>(undefined);
  const runTransfer = async () => {
    try {
      setError(undefined);
      setTransfer(undefined);
      if (!inputOldEmail || !inputNewEmail) {
        setError('A required field is missing');
        return;
      }

      const token = await auth.currentUser?.getIdToken();
      if (token) {
        setIsTransfer(true);
        setIsSearching(true);
        const res = await axios.post(
          `${process.env.REACT_APP_JOBS_BASE_URL}csa/admin/rateremail?dryRun=false`,
          {
            oldRaterEmail: inputOldEmail,
            newRaterEmail: inputNewEmail,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        setTransfer(res.data);
        setIsTransfer(false);
        setIsSearching(false);
      }
    } catch (error) {
      const e = error as Error;
      setError(e?.message ?? 'An error occurred while processing the request. Please try again.');
      setIsTransfer(false);
      setIsSearching(false);
      console.error(error);
    }
  };

  return (
    <Flex>
      <Card>
        <CardHeader>
          <Heading>Change Rater Email</Heading>
        </CardHeader>
        <CardBody>
          <VStack spacing={8} divider={<StackDivider />}>
            <Container>
              <Text>
                To change the rater email of historical ratings, the new email must be associated with a rostered user.
              </Text>
            </Container>

            <Container>
              <Grid templateRows="repeat(3, 1fr)" gap={6}>
                <GridItem>
                  <FormControl isInvalid={isOldEmailError} isRequired>
                    <FormLabel>Old Rater Email</FormLabel>
                    <Input type="email" value={inputOldEmail} onChange={handleInputChangeOldEmail} />
                    {!isOldEmailError ? (
                      <FormHelperText>Enter the current email associated with ratings.</FormHelperText>
                    ) : (
                      <FormErrorMessage>Old Rater Email is required.</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={isNewEmailError} isRequired>
                    <FormLabel>New Rater Email</FormLabel>
                    <Input type="email" value={inputNewEmail} onChange={handleInputChangeNewEmail} />
                    {!isNewEmailError ? (
                      <FormHelperText>Enter the new email to associate with ratings.</FormHelperText>
                    ) : (
                      <FormErrorMessage>New Rater Email is required.</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <Button
                    isLoading={isSearching}
                    colorScheme="blue"
                    onClick={() => {
                      runSearch();
                    }}
                  >
                    Search For Ratings
                  </Button>
                </GridItem>
              </Grid>
            </Container>
            {error ? (
              <Container>
                <Alert status="error" variant="subtle">
                  <AlertIcon />
                  <Text>{error}</Text>
                </Alert>
              </Container>
            ) : null}
            {ratingsList && ratingsList.length === 0 ? (
              <Container>
                <Alert status="warning" variant="subtle">
                  <AlertIcon />
                  <Text>No ratings were found.</Text>
                </Alert>
              </Container>
            ) : null}
          </VStack>
        </CardBody>
      </Card>
      {ratingsList ? (
        <Card>
          <CardBody>
            <VStack spacing={8} divider={<StackDivider />}>
              {ratingsList?.length ? (
                <Container>
                  <Button
                    isLoading={isTransfer}
                    colorScheme="blue"
                    onClick={() => {
                      runTransfer();
                    }}
                  >
                    {`Transfer ${ratingsList.length} Ratings`}
                  </Button>
                </Container>
              ) : null}
              {transfer ? (
                <Container>
                  <Alert status="success" variant="subtle">
                    <AlertIcon />
                    <Text>{transfer.newRaterUpdatedRatings} ratings have been transferred</Text>
                  </Alert>
                </Container>
              ) : null}
              <Container>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      {ratingsList?.length ? (
                        <Tr>
                          <Th>Rating Id</Th>
                          <Th>Rating Date</Th>
                          <Th>Program Id</Th>
                        </Tr>
                      ) : null}
                    </Thead>
                    <Tbody>
                      {ratingsList.map((rating, index) => {
                        return (
                          <Tr key={index}>
                            <Td>{rating.ratingId}</Td>
                            <Td>{utcToLocalDayAndTime(rating.createdAt)}</Td>
                            <Td>{rating.programId}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Container>
            </VStack>
          </CardBody>
        </Card>
      ) : null}
    </Flex>
  );
};

import React from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  GridItem,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import { auth } from 'services/firebase';
import { getErrorMessages } from 'services/utils';
import { MoveRatingSites, ReturnedSites } from 'models/ChangeRatingSite';

type RunUpdateProps = {
  programId: string;
  siteId: string;
  setSiteId: (value: string) => void;
  file: File;
  searchResult: MoveRatingSites;
  setSearchResult: (result: MoveRatingSites) => void;
  setError: (error: string) => void;
  setIsSearching: (isSearching: boolean) => void;
  isSearching: boolean;
};

export const UpdateRatingSite: React.FC<RunUpdateProps> = ({
  programId,
  siteId,
  setSiteId,
  file,
  searchResult,
  setError,
  setIsSearching,
  setSearchResult,
  isSearching,
}) => {
  const runUpdate = async () => {
    try {
      setError('');
      const formData = new FormData();
      if (file) {
        formData.append('file', file);
      }

      const token = await auth.currentUser?.getIdToken();
      if (token) {
        setIsSearching(true);
        const res = await axios.post(`${process.env.REACT_APP_JOBS_BASE_URL}csa/admin/update/ratingsite`, formData, {
          headers: { Authorization: `Bearer ${token}` },
          params: { pid: programId, sid: siteId },
        });
        setIsSearching(false);
        setSearchResult(res.data);
      }
    } catch (error) {
      const e = error as AxiosError;
      setError(getErrorMessages(e));
      setIsSearching(false);
    }
  };

  return (
    <>
      <Grid templateColumns="repeat(1, 1fr)" gap={6}>
        <GridItem>
          <Table>
            <Thead>
              <Tr>
                <Th>Sites</Th>
                <Th>Rating Count</Th>
                <Th>Site ID</Th>
              </Tr>
            </Thead>
            <Tbody>
              {searchResult.sites.map((site: ReturnedSites, index: number) => (
                <Tr key={index}>
                  <Td>{site.name}</Td>
                  <Td>{site.count}</Td>
                  <Td>{site.id}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </GridItem>
        <Formik initialValues={{ siteId }} onSubmit={runUpdate}>
          <Form>
            <GridItem>
              <Box>
                <Select placeholder="Select Site ID" onChange={(e) => setSiteId(e.target.value)}>
                  {searchResult.sites.map((site: ReturnedSites, index: number) => (
                    <option key={index} value={site.id}>
                      {site.id}
                    </option>
                  ))}
                </Select>
              </Box>
            </GridItem>
            <FormControl>
              <FormHelperText>The site where ratings will be transferred</FormHelperText>
            </FormControl>
            <GridItem>
              <Box mt={6}>
                <Button type="submit" isLoading={isSearching} colorScheme="blue">
                  Transfer Ratings
                </Button>
              </Box>
            </GridItem>
          </Form>
        </Formik>
      </Grid>
    </>
  );
};

import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { WarningIcon } from '@chakra-ui/icons';
import { Button, Card, CardBody, Center, Flex, Grid, Text } from '@chakra-ui/react';

import { wizardSteps } from '../../../../../constants';

const WizardFooter = ({ save }: { save: () => Promise<void>; }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [error, setError] = useState('');
  useEffect(() => {
    if (location.pathname.includes(wizardSteps[1].linkTo)) {
      setCurrentStep(1);
    } else if (location.pathname.includes(wizardSteps[2].linkTo)) {
      setCurrentStep(2);
    } else if (location.pathname.includes(wizardSteps[3].linkTo)) {
      setCurrentStep(3);
    } else if (location.pathname.includes(wizardSteps[4].linkTo)) {
      setCurrentStep(4);
    } else {
      setCurrentStep(0);
    }
  }, [location]);

  const goNext = async () => {
    try {
      setError('');
      await save();
      let basePath = location.pathname;
      wizardSteps.forEach(step => basePath = basePath.replace(step.linkTo, ''));
      if (basePath[basePath.length - 1] !== '/') {
        basePath += '/';
      }
      navigate(`${basePath + wizardSteps[currentStep + 1].linkTo}`);
    } catch (e) {
      setError(e as string);
    }
  };

  return <>
    {error ? <Card colorScheme='red' variant='filled'>
      <CardBody>
        <Flex>
          <Center><WarningIcon boxSize={5} aria-label='Wizard Error' /></Center>
          <Text pl='15px'>{error}</Text>
        </Flex>
      </CardBody>
    </Card> : <></>}
    <Grid justifyContent="flex-end" mb='50px' mt='50px'>
      <Button onClick={goNext}>Next</Button>
    </Grid>
  </>;
};

export default WizardFooter;
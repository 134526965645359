import { useEffect, useState } from 'react';

import { collection, onSnapshot } from 'firebase/firestore';
import { ExportConfig } from 'models/ExportConfig';
import { db } from 'services/firebase';
import { convertToLocalDayAndTime, getDayStringFromNumericValue, getPausedStatus } from 'services/utils';

import { DeleteIcon, InfoIcon, TriangleDownIcon, TimeIcon } from '@chakra-ui/icons';
import {
  Button,
  Container,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import AddEditExportModal from './AddEditExportModal/AddEditExportModal';
import DeleteExportModal from './DeleteExportModal/DeleteExportModal';
import styles from './Exports.module.scss';
import ManuallyTriggerExportModal from './ManuallyTriggerExportModal/ManuallyTriggerExportModal';
import { RunAdhocModal } from './RunAdhocModal/RunAdhocModal';

export const Exports: React.FC = () => {
  const [exports, setExports] = useState<ExportConfig[]>([]);
  const [selectedExport, setSelectedExport] = useState<ExportConfig | undefined>(undefined);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isRunOpen, onOpen: onRunOpen, onClose: onRunClose } = useDisclosure();
  const { isOpen: isAdhocOpen, onOpen: onAdhocOpen, onClose: onAdhocClose } = useDisclosure();

  useEffect(() => {
    onSnapshot(collection(db, `/exportConfigs`), (querySnapshot) => {
      const postData = [] as ExportConfig[];
      querySnapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id } as ExportConfig));
      setExports(
        postData.sort((a, b) => {
          if (a.org.name < b.org.name) {
            return -1;
          }
          if (a.org.name > b.org.name) {
            return 1;
          }
          return 0;
        }),
      );
    });
  }, []);

  return (
    <Container minW="1600px">
      <Flex justifyContent="end">
        <Button
          mt="25px"
          variant="solid"
          onClick={() => {
            setSelectedExport(undefined);
            onOpen();
          }}
          aria-label="Add Export"
        >
          {' '}
          Add Export
        </Button>
      </Flex>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Organization Name</Th>
              <Th>Hubspot Id</Th>
              <Th>Type</Th>
              <Th>Program Count</Th>
              <Th>Frequency</Th>
              <Th>Last Status</Th>
              <Th>Last Successful</Th>
              <Th>AdHoc Status</Th>
              <Th>AdHoc Successful</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {exports.map((exportItem, index) => {
              const status = getPausedStatus(exportItem)
                ? 'PAUSED'
                : exportItem.lastRun?.status
                ? exportItem.lastRun?.status
                : '--';
              const adhocStatus = exportItem.adhocRun?.status ? exportItem.adhocRun?.status : '--'
              const frequencyDay =
                exportItem.frequency.type === 'MONTHLY'
                  ? exportItem.frequency.dayOfMonth
                  : exportItem.frequency.type === 'WEEKLY'
                  ? exportItem.frequency.dayOfWeek
                  : null;
              const frequencyDayString = getDayStringFromNumericValue(exportItem.frequency.type, frequencyDay);
              const programCount =
                exportItem &&
                exportItem.export &&
                exportItem.export.programIds &&
                exportItem.export.programIds.length > 0
                  ? exportItem.export.programIds.length
                  : 'All';
              const exportFailed = Boolean(exportItem.lastRun?.error);
              const adhocExportFailed = Boolean(exportItem.adhocRun?.error)
              const hubspotId = exportItem?.org.hubSpotId ?? exportItem?.org.hubspotId ?? undefined;

              return (
                <Tr key={index}>
                  <Td
                    className={styles.link}
                    onClick={() => {
                      setSelectedExport(exportItem);
                      onOpen();
                    }}
                    color="blue.500"
                    textDecoration="underline"
                  >
                    {exportItem.org.name}
                  </Td>
                  <Td>{hubspotId ?? '--'}</Td>
                  <Td>{exportItem.export.type}</Td>
                  <Td>{programCount}</Td>
                  <Td>
                    {exportItem.frequency.type}{' '}
                    {exportItem.frequency.type === 'WEEKDAYS'
                      ? ''
                      : `on ${exportItem.frequency.type === 'MONTHLY' ? 'the' : ''} ${frequencyDayString}`}
                  </Td>
                  <Td>
                    {status}
                    {exportFailed && status !== 'PAUSED' && (
                      <Tooltip label={exportItem.lastRun?.error as string}>
                        <InfoIcon boxSize={5} ml="15px" />
                      </Tooltip>
                    )}
                  </Td>
                  <Td>
                    {exportItem.lastSuccessfulRunAt ? convertToLocalDayAndTime(exportItem.lastSuccessfulRunAt) : '--'}
                  </Td>
                  <Td>
                    {adhocStatus}
                    {adhocExportFailed && status !== 'PAUSED' && (
                      <Tooltip label={exportItem.adhocRun?.error}>
                        <InfoIcon boxSize={5} ml="15px" />
                      </Tooltip>
                    )}
                  </Td>
                  <Td>
                    {exportItem.adhocSuccessfulRunAt ? convertToLocalDayAndTime(exportItem.adhocSuccessfulRunAt) : '--'}
                  </Td>
                  <Td>
                    <IconButton
                      icon={<TriangleDownIcon className={styles.runExportIcon}></TriangleDownIcon>}
                      onClick={() => {
                        setSelectedExport(exportItem);
                        onRunOpen();
                      }}
                      aria-label={'Run Export'}
                      marginRight="15px"
                    />
                    <IconButton
                      icon={<DeleteIcon></DeleteIcon>}
                      onClick={() => {
                        setSelectedExport(exportItem);
                        onDeleteOpen();
                      }}
                      aria-label={'Delete Export'}
                      marginRight="15px"
                    />
                    <IconButton
                      icon={<TimeIcon />}
                      onClick={() => {
                        setSelectedExport(exportItem);
                        onAdhocOpen();
                      }}
                      aria-label={'Delete Export'}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {isOpen ? (
        <AddEditExportModal
          key={selectedExport?.id}
          exportConfig={selectedExport}
          isOpen={isOpen}
          onClose={onClose}
          setSelectedExport={setSelectedExport}
        />
      ) : null}
      <DeleteExportModal exportConfig={selectedExport} isOpen={isDeleteOpen} onClose={onDeleteClose} />
      <ManuallyTriggerExportModal exportConfig={selectedExport} isOpen={isRunOpen} onClose={onRunClose} />
     {isAdhocOpen ? ( <RunAdhocModal exportConfig={selectedExport} isOpen={isAdhocOpen} onClose={onAdhocClose}  /> ) : null }
    </Container>    
  );
};

import { useEffect, useState } from 'react';

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { FsImportConfig } from 'models/ImportConfig';
import { useParams } from 'react-router-dom';
import { db } from 'services/firebase';
import { getColumnsForFile, getFileList, isStacked } from 'services/utils';

import { SearchIcon, WarningIcon } from '@chakra-ui/icons';
import {
  Box, Button, Card, CardBody, Center, Flex, Heading, Select, Spinner, Text, useDisclosure,
} from '@chakra-ui/react';

import { apertureColumnMeta } from '../../../../../constants';
import ColumnDropdown from '../ColumnDropdown/ColumnDropdown';
import FileDropDown from '../FileDropdown/FileDropdown';
import WizardFooter from '../WizardFooter/WizardFooter';
import ColumnExpressionModal from './ColumnExpressionModal/ColumnExpressionModal';
import styles from './ColumnMapping.module.scss';
import { FsImportColumnMapVM } from './types';

const columnWidth = 450;
const padding = 15;
const labelWidth = 350;

const categories = ['Staff', 'Student', 'Site & Class', 'Student Optional'];

const ColumnMapping = () => {
  const { importId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [importConfig, setImportConfig] = useState<FsImportConfig | null>(null);
  const [columnMappings, setColumnMappings] = useState<FsImportColumnMapVM[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [hasCustomHeaders, setHasCustomHeaders] = useState<boolean>(false);
  const [columnToEdit, setColumnToEdit] = useState<FsImportColumnMapVM | undefined>(undefined);

  useEffect(() => {
    getDoc(doc(db, `/importConfigs/${importId}`)).then((querySnapshot) => {
      const data = querySnapshot.data() as FsImportConfig;
      setImportConfig(data);
      if (data?.filesConfig?.strategy === 'stack') {
        setHasCustomHeaders(!!(data?.filesConfig?.columns && data?.filesConfig?.columns.length > 0));
      } else {
        setHasCustomHeaders(false);
      }
      if (data.columnMaps) {
        const mappedData = apertureColumnMeta.map(x => {
          const savedData = data.columnMaps?.find(meta => meta.targetColumn === x.targetColumn);
          if (savedData) {
            const type = savedData.sourceExpression !== undefined ? 'expression' : 'column';
            return { ...x, ...savedData, type } as FsImportColumnMapVM;
          }
          return x as FsImportColumnMapVM;
        });
        setColumnMappings(mappedData);
      } else {
        setColumnMappings(apertureColumnMeta);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importId]);

  const editRow = (targetColumn: string, key: string, value: string) => {
    const newColumnMappings = columnMappings.map(columnMapping => {
      if (columnMapping.targetColumn === targetColumn) {
        if (key === 'type') {
          if (value === 'expression') {
            delete columnMapping.sourceColumn;
            delete columnMapping.sourceTable;
            return { ...columnMapping, sourceExpression: '', [key]: value } as FsImportColumnMapVM;
          } else {
            delete columnMapping.sourceExpression;
            return { ...columnMapping, sourceTable: '', sourceColumn: '', [key]: value } as FsImportColumnMapVM;
          }
        }
        if (key === 'sourceTable') {
          return { ...columnMapping, [key]: value, sourceColumn: '' };
        }
        return { ...columnMapping, [key]: value };
      }
      return columnMapping;
    });
    setColumnMappings(newColumnMappings);
    save(newColumnMappings);
  };

  const editExpression = (columnMap: FsImportColumnMapVM | undefined) => {
    if (columnMap) {
      const newColumnMappings = columnMappings.map(columnMapping => {
        if (columnMapping.targetColumn === columnMap?.targetColumn) {
          return columnMap;
        }
        return columnMapping;
      });
      setColumnMappings(newColumnMappings);
      save(newColumnMappings);
    }
  };

  const openEditExpessionModal = (columnMap: FsImportColumnMapVM) => {
    setColumnToEdit(columnMap);
    onOpen();
  };

  const save = async (newColumnMappings?: FsImportColumnMapVM[]) => {
    const columnMaps = (newColumnMappings ?? columnMappings).map(x => {
      if (!x.sourceExpression && (!x.sourceColumn || (!x.sourceTable && !isStacked(importConfig)))) {
        return undefined;
      } else {
        // remove the VM properties before saving
        const { required, label, category, type, ...columnMap } = x;
        columnMap.note ?? delete columnMap.note;
        return columnMap;
      }
    });
    const filteredColumnMaps = columnMaps.filter(x => !!x);
    if (filteredColumnMaps.length > 0) {
      await updateDoc(doc(db, `/importConfigs/${importId}`), { columnMaps: filteredColumnMaps });
    }
  };

  const validate = async () => {
    setErrors([]);
    const newErrors = [] as string[];
    columnMappings.filter(x => x.required).forEach(x => {
      if (!x.sourceExpression && (!x.sourceColumn || (!x.sourceTable && !isStacked(importConfig)))) {
        newErrors.push(`${x.label} is required`);
      }
    });
    setErrors(newErrors);
    if (newErrors.length > 0) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  };

  const buildTypeSection = (category: string, indexKey: number) => {
    return <Box key={indexKey}>
      <Heading as='h4' size="md" pb='25px' pt='25px' textDecoration='underline'>{category}</Heading>
      <Flex direction='row' pb={`${padding}px`}>
        <Box w={`${labelWidth}px`}></Box>
        <Box w={`${columnWidth}px`}><Text fontSize='sm' fontWeight='bold'>Type</Text></Box>
        {isStacked(importConfig) ? <></> :
          <Box w={`${columnWidth}px`}><Text fontSize='sm' fontWeight='bold'>File</Text></Box>
        }
        <Box w={`${columnWidth}px`}><Text fontSize='sm' fontWeight='bold'>Column</Text></Box>
      </Flex>
      {columnMappings.filter(x => x.category === category).map((columnMapping, index) => buildRowForType(columnMapping, index))}
    </Box>;
  };

  const buildRowForType = (item: FsImportColumnMapVM, index: number) => {
    return <Flex direction='row' key={`${index + JSON.stringify(item)}`} pb={`${padding}px`}>
      <Box w={`${labelWidth}px`}><Text fontSize='sm'>{item.label} {item.required ? '*' : ''}</Text></Box>
      <Box w={`${columnWidth}px`} pr={`${padding}px`}>
        <Select defaultValue={item.type} onChange={(v) => { editRow(item.targetColumn, 'type', v.target.value); }}>
          <option value='column' key={`${index + item.label}-column`}>column</option>
          <option value='expression' key={`${index + item.label}-expression`}>expression</option>
        </Select>
      </Box>
      {item.type === 'column' ? <>
        {isStacked(importConfig) ? <></> :
          <Box w={`${columnWidth}px`} pr={`${padding}px`}>
            <FileDropDown value={item.sourceTable} fileList={getFileList(importConfig)} onChange={(v: string) => { editRow(item.targetColumn, 'sourceTable', v); }}></FileDropDown>
          </Box>
        }
        <Box w={`${columnWidth}px`} pr={`${padding}px`}>
          <ColumnDropdown value={item.sourceColumn} columnNames={getColumnsForFile(importConfig, hasCustomHeaders, item.sourceTable)} onChange={(v: string) => { editRow(item.targetColumn, 'sourceColumn', v); }} /></Box>
      </> : <>
        <Box w={`${isStacked(importConfig) ? columnWidth : columnWidth * 2}px`} pr={`${isStacked(importConfig) ? padding : padding * 2}px`}><Button variant='ghost' onClick={() => { openEditExpessionModal(item); }}><SearchIcon /> <Text ml={`${padding}px`}>{item.sourceExpression ? 'Edit Expresssion' : 'Create Expression'}</Text></Button></Box>
      </>}
    </Flex>;
  };

  return <>
    <Heading as='h2' size="md">Column Map</Heading>
    <Text fontSize='sm' pb='20px'>How should we map customer data to the Aperture import structure?</Text>
    <Box className={styles.main_body} mb='50px'>
      {!!importConfig ? <>
        {categories.map((x, index) => buildTypeSection(x, index))}
        <ColumnExpressionModal isOpen={isOpen} onClose={onClose} onUpdate={editExpression} columnMap={columnToEdit} importConfig={importConfig} />
      </> : <Center h='300px'><Spinner></Spinner></Center>}
    </Box>
    {errors.length > 0 ? <Card colorScheme='red' variant='filled'>
      <CardBody>
        {errors.map((error, index) =>
          <Flex key={index}>
            <Center><WarningIcon boxSize={5} aria-label='Column Mappings Errors' /></Center>
            <Text pl={`${padding}px`}>{error}</Text>
          </Flex>
        )}
      </CardBody>
    </Card> : <></>}
    <WizardFooter save={validate} />
  </>;
};

export default ColumnMapping;
import { useEffect, useState } from 'react';

import { FsImportConfig } from 'models/ImportConfig';

import {
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Textarea,
} from '@chakra-ui/react';

const ImportConfigModal = ({
  isOpen,
  onClose,
  onUpdate,
  importConfig,
}: {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (
    hubspotId: string | undefined,
    note: string | undefined,
    paused: boolean | undefined,
    isSiteCountCheckEnabled: boolean | undefined,
    allowSubfolders: boolean | undefined,
  ) => void;
  importConfig: FsImportConfig | null;
}) => {
  const [hubspotId, setHubspotId] = useState<string | undefined>(undefined);
  const [note, setNote] = useState<string | undefined>(undefined);
  const [automatedImports, setAutomatedImports] = useState<boolean | undefined>(undefined);
  const [isSiteCountCheckEnabled, setIsSiteCountCheckEnabled] = useState<boolean | undefined>(undefined);
  const [allowSubfolders, setAllowSubfolders] = useState<boolean | undefined>(
    importConfig?.sftpConfig?.allowSubfolders,
  );

  useEffect(() => {
    setHubspotId(importConfig?.hubspotId);
    setNote(importConfig?.note);
    setAutomatedImports(importConfig?.pausedAt ? false : true);
    setIsSiteCountCheckEnabled(importConfig?.isSiteCountCheckEnabled);
    setAllowSubfolders(importConfig?.sftpConfig?.allowSubfolders);
  }, [importConfig]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Program Import Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormLabel>
            HubSpot Id
            <Input
              placeholder="HubSpot Id"
              defaultValue={importConfig?.hubspotId}
              onChange={(v) => setHubspotId(v.target.value)}
            />
          </FormLabel>
          <FormLabel pt="15px" pb="15px">
            Enable site count check?
            <Switch
              ml="15px"
              id="site-count-check"
              isChecked={isSiteCountCheckEnabled}
              onChange={(v) => {
                setIsSiteCountCheckEnabled(v.target.checked);
              }}
            />
          </FormLabel>
          <FormLabel pt="15px" pb="15px">
            Enable automated imports?
            <Switch
              ml="15px"
              id="pause-imports"
              isChecked={automatedImports}
              onChange={(v) => {
                setAutomatedImports(v.target.checked);
              }}
            />
          </FormLabel>
          <FormLabel pt="15px" pb="15px">
            Enable processing subfolders?
            <Switch
              ml="15px"
              id="processing-subfolders"
              isChecked={allowSubfolders}
              onChange={(v) => {
                setAllowSubfolders(v.target.checked);
              }}
            />
          </FormLabel>
          <FormLabel>
            Note
            <Textarea
              h="150px"
              placeholder="Note"
              defaultValue={importConfig?.note}
              onChange={(v) => setNote(v.target.value)}
            />
          </FormLabel>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              onClose();
              onUpdate(hubspotId, note, automatedImports, isSiteCountCheckEnabled, allowSubfolders);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImportConfigModal;

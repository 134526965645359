import React from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  GridItem,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import { auth } from 'services/firebase';
import { getErrorMessages } from 'services/utils';
import { MoveRatingPeriods, Period } from 'models/ChangeAssignmentPeriod';

type RunUpdateProps = {
  programId: string;
  ratingPeriod: string;
  setRatingPeriod: (value: string) => void;
  file: File;
  searchResult: MoveRatingPeriods;
  setSearchResult: (result: MoveRatingPeriods) => void;
  setError: (error: string) => void;
  setIsSearching: (isSearching: boolean) => void;
  isSearching: boolean;
};

const getPeriodNames = (length: number) => {
  switch (length) {
    case 1:
      return ['Annual'];
    case 2:
      return ['Pre', 'Post'];
    case 3:
      return ['Pre', 'Mid', 'Post'];
    case 4:
      return ['Pre', 'Mid 1', 'Mid 2', 'Post'];
    case 5:
      return ['Pre', 'Mid 1', 'Mid 2', 'Mid 3', 'Post'];
    default:
      return [];
  }
};

export const UpdateAssignmentPeriod: React.FC<RunUpdateProps> = ({
  programId,
  ratingPeriod,
  setRatingPeriod,
  file,
  searchResult,
  setError,
  setIsSearching,
  setSearchResult,
  isSearching,
}) => {
  const periodNames = getPeriodNames(searchResult.periods.length);
  const runUpdate = async () => {
    try {
      setError('');
      const formData = new FormData();
      if (file) {
        formData.append('file', file);
      }

      const token = await auth.currentUser?.getIdToken();
      if (token) {
        setIsSearching(true);
        const res = await axios.post(`${process.env.REACT_APP_JOBS_BASE_URL}csa/admin/update/ratingperiod`, formData, {
          headers: { Authorization: `Bearer ${token}` },
          params: { pid: programId, period: ratingPeriod },
        });
        setIsSearching(false);
        setSearchResult(res.data);
      }
    } catch (error) {
      const e = error as AxiosError;
      setError(getErrorMessages(e));
      setIsSearching(false);
    }
  };

  return (
    <>
      <Grid templateColumns="repeat(1, 1fr)" gap={6}>
        <GridItem>
          <Table>
            <Thead>
              <Tr>
                <Th>Rating Window</Th>
                <Th>Rating Count</Th>
                <Th>Date Range</Th>
              </Tr>
            </Thead>
            <Tbody>
              {searchResult.periods.map((period: Period, order: number) => (
                <Tr key={order}>
                  <Td>{periodNames[order]}</Td>
                  <Td>{period.count}</Td>
                  <Td>
                    {period.dateRange.begin} - {period.dateRange.end}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </GridItem>
        <Formik initialValues={{ ratingPeriod }} onSubmit={runUpdate}>
          <Form>
            <GridItem>
              <Box>
                <Select placeholder="Select Assignment Period" onChange={(e) => setRatingPeriod(e.target.value)}>
                  {searchResult.periods.map((period: Period, order: number) => (
                    <option key={order} value={period.order}>
                      {periodNames[order]}
                    </option>
                  ))}
                </Select>
              </Box>
            </GridItem>
            <FormControl>
              <FormHelperText>The rating window that ratings will be transferred to</FormHelperText>
            </FormControl>
            <GridItem>
              <Box mt={6}>
                <Button type="submit" isLoading={isSearching} colorScheme="blue">
                  Transfer Ratings
                </Button>
              </Box>
            </GridItem>
          </Form>
        </Formik>
      </Grid>
    </>
  );
};

import { useCallback, useEffect, useState } from 'react';

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { FsImportConfig, FsImportResultFilter, FsImportSourceFilter } from 'models/ImportConfig';
import { useParams } from 'react-router-dom';
import { auth, db } from 'services/firebase';
import { getColumnsForFile, getFileList } from 'services/utils';
import { v4 as uuidv4 } from 'uuid';

import { AddIcon, AttachmentIcon, DeleteIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  IconButton,
  Input,
  Select,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { apertureColumnMeta, operators } from '../../../../../constants';
import ColumnDropdown from '../ColumnDropdown/ColumnDropdown';
import FileDropDown from '../FileDropdown/FileDropdown';
import WizardFooter from '../WizardFooter/WizardFooter';
import styles from './Filter.module.scss';
import FilterNoteModal from './FilterNoteModal/FilterNoteModal';
import { FilterType, NoteModalData } from './types';
import axios from 'axios';

// TODO: A lot of things could be changed to more generic so its not the same code for pre/post with if statements
const Filter = () => {
  const { importId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [importConfig, setImportConfig] = useState<FsImportConfig | null>(null);
  const [hasCustomHeaders, setHasCustomHeaders] = useState<boolean>(false);
  const [preMapFilters, setPreMapFilters] = useState<Map<string, FsImportSourceFilter[]>>(new Map());
  const [postMapFilters, setPostMapFilters] = useState<Map<string, FsImportResultFilter[]>>(new Map());
  const [filterForNote, setFilterForNote] = useState<NoteModalData>(undefined);
  const [possibleTables, setPossibleTables] = useState<string[] | undefined>(undefined);
  const [intColumn, setIntColumn] = useState<Record<string, string[]>>({});

  useEffect(() => {
    getDoc(doc(db, `/importConfigs/${importId}`)).then((querySnapshot) => {
      auth.currentUser?.getIdToken().then((token) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}csa/import/filter-tables/`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((data: { data: string[] }) => {
            setPossibleTables(data.data);
            const promises = data.data.map((possibleTable: string) => {
              return axios.get(`${process.env.REACT_APP_API_URL}csa/import/filter-tables/${possibleTable}/columns`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            });

            Promise.all(promises).then((responses) => {
              const intColumnPayload: { [key: string]: string[] } = {};
              for (const possibleTable of data.data as string[]) {
                const response = responses[data.data.indexOf(possibleTable)];
                intColumnPayload[possibleTable] = response.data;
              }
              setIntColumn(intColumnPayload);
            });
          });
      });
      const data = querySnapshot.data() as FsImportConfig;
      setImportConfig(data);
      if (data.sourceFilters) {
        const sourceFilters = new Map();
        data.sourceFilters.forEach((filter: FsImportSourceFilter) => {
          const value = sourceFilters.get(filter.group) as FsImportSourceFilter[];
          if (value) {
            value.push(filter);
            sourceFilters.set(filter.group, value);
          } else {
            sourceFilters.set(filter.group, [filter]);
          }
        });
        setPreMapFilters(sourceFilters);
      }

      if (data.resultFilters) {
        const resultFilters = new Map();
        data.resultFilters.forEach((filter: FsImportSourceFilter) => {
          const value = resultFilters.get(filter.group) as FsImportSourceFilter[];
          if (value) {
            value.push(filter);
            resultFilters.set(filter.group, value);
          } else {
            resultFilters.set(filter.group, [filter]);
          }
        });
        setPostMapFilters(resultFilters);
      }
      if (data?.filesConfig?.strategy === 'stack') {
        setHasCustomHeaders(!!(data?.filesConfig?.columns && data?.filesConfig?.columns.length > 0));
      } else {
        setHasCustomHeaders(false);
      }
    });
  }, [importId]);

  const save = useCallback(
    async (type: FilterType, filters: Map<string, FsImportSourceFilter[]> | Map<string, FsImportResultFilter[]>) => {
      let filterArray = [] as FsImportSourceFilter[] | FsImportResultFilter[];
      [...filters.values()].forEach((x) => {
        filterArray = [...filterArray, ...x];
      });
      if (type === 'pre') {
        filterArray = (filterArray as FsImportSourceFilter[]).filter((x) => {
          if (
            !x.column ||
            !x.operator ||
            !x.value ||
            (importConfig?.filesConfig?.strategy === 'join' && !x.table) ||
            (x.operator === 'in_table' && !x.inTableColumn) || (x.operator === 'not_in_table' && !x.inTableColumn)
          ) {
            return false;
          }
          return true;
        });
        await updateDoc(doc(db, `/importConfigs/${importId}`), { sourceFilters: filterArray });
      } else {
        filterArray = (filterArray as FsImportResultFilter[]).filter((x) => {
          if (!x.column || !x.operator || !x.value || (x.operator === 'in_table' && !x.inTableColumn) || (x.operator === 'not_in_table' && !x.inTableColumn)) {
            return false;
          }
          return true;
        });
        await updateDoc(doc(db, `/importConfigs/${importId}`), { resultFilters: filterArray });
      }
    },
    [importConfig?.filesConfig?.strategy, importId],
  );

  const editRow = useCallback(
    (type: FilterType, groupId: string, index: number, key: string, value: string) => {
      if (type === 'pre') {
        const filters = preMapFilters.get(groupId);
        const newPreMapFilters = new Map(preMapFilters);
        if (filters && filters.length > 0) {
          const obj = filters[index];
          if (key === 'table') {
            filters[index] = { ...obj, [key]: value, column: '' } as FsImportSourceFilter;
          } else {
            filters[index] = { ...obj, [key]: value } as FsImportSourceFilter;
          }

          newPreMapFilters.set(groupId, filters);
          setPreMapFilters(newPreMapFilters);
          save(type, newPreMapFilters);
        }
      } else {
        const filters = postMapFilters.get(groupId);
        const newPostMapFilters = new Map(postMapFilters);
        if (filters && filters.length > 0) {
          const obj = filters[index];
          filters[index] = { ...obj, [key]: value } as FsImportResultFilter;
          newPostMapFilters.set(groupId, filters);
          setPostMapFilters(newPostMapFilters);
          save(type, newPostMapFilters);
        }
      }
    },
    [postMapFilters, preMapFilters, save],
  );

  const addMapRow = (type: FilterType, groupId: string) => {
    if (type === 'pre') {
      const value = preMapFilters?.get(groupId) || [];
      value.push({ group: groupId, column: '', value: '' });
      preMapFilters?.set(groupId, value);
      setPreMapFilters(new Map(preMapFilters));
    } else {
      const value = postMapFilters?.get(groupId) || [];
      value.push({ group: groupId, value: '' });
      postMapFilters?.set(groupId, value);
      setPostMapFilters(new Map(postMapFilters));
    }
  };

  const deleteMapRow = (type: FilterType, groupId: string, index: number) => {
    if (type === 'pre') {
      const group = preMapFilters.get(groupId);
      if (group) {
        group.splice(index, 1);
        preMapFilters.set(groupId, group);
        const newPreMapFilters = new Map(preMapFilters);
        setPreMapFilters(newPreMapFilters);
        save(type, newPreMapFilters);
      }
    } else {
      const group = postMapFilters.get(groupId);
      if (group) {
        group.splice(index, 1);
        postMapFilters.set(groupId, group);
        const newPostMapFilters = new Map(postMapFilters);
        setPostMapFilters(new Map(postMapFilters));
        save(type, newPostMapFilters);
      }
    }
  };

  const addGroup = (type: FilterType) => {
    const id = uuidv4();
    addMapRow(type, id);
  };

  const deleteGroup = (type: FilterType, groupId: string) => {
    if (type === 'pre') {
      preMapFilters.delete(groupId);
      setPreMapFilters(new Map(preMapFilters));
      save(type, preMapFilters);
    } else {
      postMapFilters.delete(groupId);
      setPostMapFilters(new Map(postMapFilters));
      save(type, postMapFilters);
    }
  };

  const openNoteModal = (noteModalData: NoteModalData) => {
    setFilterForNote(noteModalData);
    onOpen();
  };

  const getNumOfColumns = (type: FilterType) => {
    let numOfColumns = 4;
    if (type === 'pre' && importConfig?.filesConfig?.strategy === 'join') {
      numOfColumns = 5;
    }
    return numOfColumns;
  };

  const buildMapRow = (filter: FsImportSourceFilter, index: number, type: FilterType) => {
    return (
      <SimpleGrid
        columns={getNumOfColumns(type)}
        justifyContent="space-between"
        key={`${index + JSON.stringify(filter)}`}
        pb="15px"
      >
        {type === 'pre' && importConfig?.filesConfig?.strategy === 'join' ? (
          <Box pr="15px">
            <FileDropDown
              value={filter.table}
              fileList={getFileList(importConfig)}
              onChange={(v: string) => {
                editRow(type, filter.group, index, 'table', v);
              }}
            ></FileDropDown>
          </Box>
        ) : (
          <></>
        )}
        <Box pr="15px">
          {type === 'pre' ? (
            <ColumnDropdown
              value={filter.column}
              columnNames={getColumnsForFile(importConfig, hasCustomHeaders, filter.table)}
              onChange={(v: string) => {
                editRow(type, filter.group, index, 'column', v);
              }}
            />
          ) : (
            <Select
              placeholder="Select Column"
              defaultValue={filter.column}
              onChange={(v) => {
                editRow(type, filter.group, index, 'column', v.target.value);
              }}
            >
              {apertureColumnMeta.map((x, index) => (
                <option key={index + x.label} value={x.targetColumn}>
                  {x.label}
                </option>
              ))}
            </Select>
          )}
        </Box>
        <Box pr="15px">
          <Select
            placeholder="Select Operator"
            defaultValue={filter.operator}
            onChange={(v) => {
              editRow(type, filter.group, index, 'operator', v.target.value);
            }}
          >
            {operators.map((operator, index) => (
              <option value={operator.value} key={index}>
                {operator.label}
              </option>
            ))}
          </Select>
        </Box>
        <Box pr="15px">
          {(filter.operator === 'in_table' || filter.operator === 'not_in_table') && possibleTables ? (
            <>
              <Select
                placeholder="Select table"
                defaultValue={filter.value}
                onChange={(v) => {
                  editRow(type, filter.group, index, 'value', v.target.value);
                }}
              >
                {possibleTables.map((possibleTable, index) => (
                  <option value={possibleTable} key={index}>
                    {possibleTable}
                  </option>
                ))}
              </Select>
              <Box pt="15px">
                <Text fontSize="sm" fontWeight="bold">
                  Column
                </Text>
                <Select
                  placeholder="Select column"
                  value={filter.inTableColumn}
                  onChange={(v) => {
                    editRow(type, filter.group, index, 'inTableColumn', v.target.value);
                  }}
                >
                  {intColumn[filter.value]?.map((inTableColumn, index) => (
                    <option value={inTableColumn} key={index}>
                      {inTableColumn}
                    </option>
                  ))}
                </Select>
              </Box>
            </>
          ) : (
            <Input
              defaultValue={filter.value}
              onBlurCapture={(v) => {
                editRow(type, filter.group, index, 'value', v.target.value);
              }}
            ></Input>
          )}
        </Box>
        <Flex direction="row" pr="15px">
          <Box pr="15px">
            <div className={styles.notification_container}>
              <IconButton
                icon={<AttachmentIcon />}
                variant="ghost"
                onClick={() => {
                  openNoteModal({ type, index, filter });
                }}
                aria-label="Open Note Modal"
              />
              {filter.note ? <span className={styles.notification_icon}></span> : <></>}
            </div>
          </Box>
          <Box>
            <IconButton
              icon={<DeleteIcon />}
              variant="ghost"
              onClick={() => {
                deleteMapRow(type, filter.group, index);
              }}
              aria-label="Delete Pre Map Row"
            />
          </Box>
        </Flex>
      </SimpleGrid>
    );
  };

  const buildMapGroup = (list: FsImportSourceFilter[], groupIndex: number, type: FilterType) => {
    let groupId = [...preMapFilters.keys()][groupIndex];
    if (type === 'post') {
      groupId = [...postMapFilters.keys()][groupIndex];
    }
    return (
      <Card variant="outline" key={`premap-group-${groupIndex}`} p="25px" mb="25px">
        <SimpleGrid columns={getNumOfColumns(type)} justifyContent="space-between">
          {type === 'pre' && importConfig?.filesConfig?.strategy === 'join' ? (
            <Box pr="15px">
              <Text fontSize="sm" fontWeight="bold">
                File
              </Text>
            </Box>
          ) : (
            <></>
          )}
          <Box pr="15px">
            <Text fontSize="sm" fontWeight="bold">
              Column
            </Text>
          </Box>
          <Box pr="15px">
            <Text fontSize="sm" fontWeight="bold">
              Operator
            </Text>
          </Box>
          <Box pr="15px">
            <Text fontSize="sm" fontWeight="bold">
              Value
            </Text>
          </Box>
          <Box w="115px" pr="15px"></Box>
        </SimpleGrid>
        {list.map((filter, index) => buildMapRow(filter, index, type))}
        <Flex>
          <Button
            mt="25px"
            leftIcon={<AddIcon />}
            variant="ghost"
            onClick={() => {
              addMapRow(type, groupId);
            }}
            aria-label="Add Filter"
          >
            Add Filter
          </Button>
          <Spacer />
          <Button
            mt="25px"
            leftIcon={<DeleteIcon />}
            variant="ghost"
            onClick={() => {
              deleteGroup(type, groupId);
            }}
            aria-label="Delete Group"
          >
            Delete Group
          </Button>
        </Flex>
      </Card>
    );
  };

  return (
    <>
      <Heading as="h2" size="md" mb="15px">
        Filters
      </Heading>
      <Card mb="15px" p="10px" colorScheme="cyan" variant="filled">
        <CardBody>
          <Flex>
            <Center>
              <InfoIcon boxSize={5} aria-label="Filter Info" />
            </Center>
            <Text pl="15px">{`Records will be included if they match all the filters for at least one group. Filters within a group are AND'd together while groups are OR'd together.`}</Text>
          </Flex>
        </CardBody>
      </Card>
      <Box className={styles.main_body} mb="50px" pr="15px">
        <Box pb="25px">
          <Heading as="h3" size="md">
            Pre-mapping Filters
          </Heading>
          <Text fontSize="sm">How should we filter customer data before it is mapped?</Text>
          {preMapFilters ? (
            [...preMapFilters.values()].map((x: FsImportSourceFilter[], index: number) =>
              buildMapGroup(x, index, 'pre'),
            )
          ) : (
            <></>
          )}
          <Flex>
            <Button
              mt="25px"
              leftIcon={<AddIcon />}
              variant="ghost"
              onClick={() => {
                addGroup('pre');
              }}
              aria-label="Add Filter Group"
            >
              Add Filter Group
            </Button>
          </Flex>
        </Box>
        <Box pb="25px">
          <Heading as="h3" size="md">
            Post-mapping Filters
          </Heading>
          <Text fontSize="sm">How should we filter customer data after it is mapped?</Text>
          {postMapFilters ? (
            [...postMapFilters.values()].map((x: FsImportResultFilter[], index: number) =>
              buildMapGroup(x, index, 'post'),
            )
          ) : (
            <></>
          )}
          <Button
            mt="25px"
            leftIcon={<AddIcon />}
            variant="ghost"
            onClick={() => {
              addGroup('post');
            }}
            aria-label="Add Filter Group"
          >
            Add Filter Group
          </Button>
        </Box>
      </Box>
      <WizardFooter
        save={() => {
          return Promise.resolve();
        }}
      />
      <FilterNoteModal
        isOpen={isOpen}
        onClose={onClose}
        onUpdate={(value: string | undefined) => {
          if (filterForNote && value) {
            editRow(filterForNote.type, filterForNote.filter.group, filterForNote.index, 'note', value);
          }
        }}
        noteModalData={filterForNote}
      />
    </>
  );
};

export default Filter;

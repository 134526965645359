import { useEffect, useState } from 'react';

import { deleteField, doc, FieldValue, onSnapshot, Timestamp, updateDoc } from 'firebase/firestore';
import { FsImportConfig } from 'models/ImportConfig';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { db } from 'services/firebase';

import { ArrowBackIcon, SettingsIcon, WarningIcon } from '@chakra-ui/icons';
import { Button, Center, Flex, IconButton, SimpleGrid, Spacer, Tooltip, useDisclosure } from '@chakra-ui/react';

import { onEditStepRegex } from '../../../constants';
import styles from './Import.module.scss';
import ImportConfigModal from './importConfigModal/importConfigModal';
import SampleData from './MergeStrategy/SampleData/SampleData';
import WizardHeader from './MergeStrategy/WizardHeader/WizardHeader';
import { FirestoreUtil } from 'services/utils';
import { OneRosterUtil } from '../../../services/oneroster';

const Import = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { importId } = useParams();
  const [importConfig, setImportConfig] = useState<FsImportConfig | null>(null);
  const [onEditStep, setOnEditStep] = useState<boolean>(true);

  useEffect(() => {
    setOnEditStep(onEditStepRegex.test(location.pathname));
    onSnapshot(doc(db, `/importConfigs/${importId}`), (querySnapshot) => {
      setImportConfig({ ...(querySnapshot.data() as FsImportConfig), id: querySnapshot.id });
    });
  }, [importId, location]);

  const updateImportConfig = async (
    hubspotId: string | undefined,
    note: string | undefined,
    automatedImports: boolean | undefined,
    isSiteCountCheckEnabled: boolean | undefined,
    allowSubfolders: boolean | undefined,
  ) => {
    try {
      const pausedAt = automatedImports ? undefined : Timestamp.now();
      const body = {
        hubspotId: hubspotId || deleteField(),
        note: note || deleteField(),
        pausedAt: pausedAt || deleteField(),
        isSiteCountCheckEnabled: isSiteCountCheckEnabled || deleteField(),
        sftpConfig: {
          allowSubfolders: allowSubfolders ? allowSubfolders : deleteField(),
        },
      };
      await updateDoc(
        doc(db, `/importConfigs/${importId}`),
        FirestoreUtil.toDotNotation(body) as { [x: string]: FieldValue | Partial<unknown> | undefined },
      );
    } catch (e) {
      console.error(e);
    }
  };

  const setupOneRoster = async () => {
    try {
      const body = OneRosterUtil.updateConfig(importConfig);
      if (!body) return;

      await updateDoc(
        doc(db, `/importConfigs/${importId}`),
        FirestoreUtil.toDotNotation(body) as { [x: string]: FieldValue | Partial<unknown> | undefined },
      );
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Flex gap="2">
        <div>
          <IconButton
            size="lg"
            variant="ghost"
            onClick={() => {
              navigate(`/`);
            }}
            aria-label="Go Back"
            icon={<ArrowBackIcon boxSize="2rem" />}
          />
          <h1 className={styles.import_name}>
            {importConfig?.name} ({importConfig?.hubspotId ?? 'Hubspot Id has not been set'}){' '}
            {importConfig?.note ? (
              <Tooltip label={importConfig?.note}>
                <WarningIcon />
              </Tooltip>
            ) : (
              <></>
            )}{' '}
          </h1>
        </div>
        <Spacer />
        {OneRosterUtil.hasOneRosterFiles(importConfig) ? (
          <Button
            onClick={() => {
              setupOneRoster();
            }}
          >
            Setup OneRoster
          </Button>
        ) : null}
        <IconButton onClick={onOpen} aria-label="Settings" icon={<SettingsIcon />} />
      </Flex>
      <SimpleGrid className={styles.body_section} columns={2} spacing={10}>
        <SampleData importConfig={importConfig} onEditStep={onEditStep} />
        <div>
          <WizardHeader />
          <div className={styles.wizard_container}>
            {importConfig?.filesMeta ? (
              <Outlet />
            ) : (
              <Center h="320px">You can change the configuration when files are available</Center>
            )}
          </div>
        </div>
      </SimpleGrid>
      <ImportConfigModal isOpen={isOpen} onClose={onClose} onUpdate={updateImportConfig} importConfig={importConfig} />
    </>
  );
};

export default Import;

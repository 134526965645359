import { useEffect, useState } from 'react';

import siginIn from 'assets/google_sign_in.png';

import { auth, signInWithGoogle } from '../../services/firebase';
import styles from './Login.module.scss';

const Login = () => {
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user?.getIdTokenResult().then((result) => {
          // after updating firebase from 9.15.0 to 10.11.1, although the type has changed,
          // the runtime value for result.claims.portals is still an array
          if (result.claims.portals && (result.claims.portals as string[]).includes('csa')) {
            setError(false);
          } else {
            setError(true);
          }
        });
      }
    });
  }, []);

  return (
    <div className={styles.login_container}>
      <button className="button" onClick={signInWithGoogle}>
        <img src={siginIn} alt="Google Sign in" />
      </button>
      <div className={styles.error_container}>{error ? <p>User does not have access</p> : <></>}</div>
    </div>
  );
};

export default Login;

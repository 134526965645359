import { useState } from 'react';
import axios from 'axios';
import { ExportConfig } from 'models/ExportConfig';
import { auth } from 'services/firebase';
import { exportTypes } from 'models/ExportConfig';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Spinner,
  Center,
} from '@chakra-ui/react';

const ManuallyTriggerExportModal = ({
  isOpen,
  onClose,
  exportConfig,
}: {
  isOpen: boolean;
  onClose: () => void;
  exportConfig: ExportConfig | undefined;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const runExport = async () => {
    if (exportConfig) {
      auth.currentUser?.getIdToken().then((token) => {
        setIsLoading(true);
        axios
          .post(`${process.env.REACT_APP_JOBS_BASE_URL}csa/export/${exportConfig.id}`, null, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(() => {
            setIsLoading(false);
            onClose();
          })
          .then((e) => {
            setIsLoading(false);
            console.error(e);
          });
      });
    }
  };

  const exportType = exportTypes.find((ex) => ex.value === exportConfig?.export.type)?.label;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Run Export</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Text>
              Are you sure you want to run a one-off {exportType} for “{exportConfig?.org.name}”?
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="blue" onClick={runExport}>
            Run
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ManuallyTriggerExportModal;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { doc, updateDoc } from 'firebase/firestore';
import { FsImportConfig, FsImportFileMeta } from 'models/ImportConfig';
import { auth, db } from 'services/firebase';
import { convertToLocalDayAndTime, getColumnsForFile } from 'services/utils';
import { LinkIcon, ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  IconButton,
  Input,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import DistinctValuesModal from './DistinctValuesModal/DistinctValuesModal';
import DeleteFileModal from './DeleteFileModal';
import styles from './SampleData.module.scss';
import axios from 'axios';

interface FileData extends FsImportFileMeta {
  data: unknown[];
}

const SampleData = ({ importConfig, onEditStep }: { importConfig: FsImportConfig | null; onEditStep: boolean }) => {
  const [filesData, setFilesData] = useState<FileData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [distinctValues, setDistinctValues] = useState<{
    table: string;
    column: string;
    dataHeader: string | undefined;
  } | null>(null);
  const [hasCutomHeaders, setHasCustomHeaders] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState<string>('');
  useEffect(() => {
    if (importConfig?.filesConfig?.strategy === 'stack') {
      setHasCustomHeaders(!!(importConfig?.filesConfig?.columns && importConfig?.filesConfig?.columns.length > 0));
    } else {
      setHasCustomHeaders(false);
    }
  }, [importConfig]);

  const getAllTableData = async () => {
    setLoading(true);
    if (importConfig != null && importConfig !== undefined && importConfig.filesMeta) {
      await setFilesData([]);
      const promises = await Object.values(importConfig.filesMeta).map(async (meta) => {
        return new Promise(async (res, rej) => {
          const data = await getTableData(meta);
          res(data);
        });
      });
      const result = (await Promise.all(promises)) as FileData[];
      result.sort((a, b) => {
        return b.uploadedAt.toDate().getTime() - a.uploadedAt.toDate().getTime();
      });
      setFilesData(result);
    }
    setLoading(false);
  };

  const updateColumnName = async (value: string, index: number) => {
    const columns = importConfig?.filesConfig?.columns || [];
    columns[index] = value;
    const newConfig = { ...importConfig?.filesConfig, columns };
    await updateDoc(doc(db, `/importConfigs/${importConfig?.id}`), { filesConfig: newConfig });
  };

  const getTableData = async (meta: FsImportFileMeta): Promise<FileData> => {
    const token = await auth.currentUser?.getIdToken();
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}csa/import/sample/table/${meta.name}?rowCount=10`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return { ...meta, data: data as unknown[] };
  };

  const openDistinctValuesModal = (table: string, column: string, dataHeader: string | undefined) => {
    setDistinctValues({ table, column, dataHeader });
    onOpen();
  };

  const showDataForTable = (tableName: string, editable: boolean) => {
    if (importConfig?.filesMeta) {
      const firstFileMeta = Object.values(importConfig?.filesMeta)[0].columns;

      const fileData = filesData.find((x) => x.name === tableName);
      if (fileData && fileData.data.length > 0 && importConfig?.filesMeta !== undefined) {
        const columnNames = getColumnsForFile(importConfig, hasCutomHeaders, fileData.name, true) || '';

        return (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  {Object.values(importConfig.filesMeta)[0].columns.map((columnName, index) => (
                    <Th key={index}>
                      {editable ? (
                        <Input
                          minW="100px"
                          defaultValue={columnNames[index]}
                          onBlurCapture={(element) => {
                            updateColumnName(element.target.value, index);
                          }}
                        />
                      ) : (
                        columnNames[index]
                      )}
                      <IconButton
                        key={`icon-${index}`}
                        size="xs"
                        variant="ghost"
                        onClick={() => {
                          openDistinctValuesModal(fileData.name, columnNames[index], firstFileMeta[index]);
                        }}
                        aria-label="Show Distinct Values"
                        icon={<ViewIcon />}
                      />
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {fileData?.data.map((data: any, index) => {
                  return (
                    <Tr key={index}>
                      {fileData?.columns.map((dataColumnName, yIndex) => (
                        <Td key={yIndex}>{data[dataColumnName]}</Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        );
      }
      return <></>;
    }
  };

  return (
    <Stack pt="50px">
      <Heading as="h2" size="md" pb="20px">
        Sample Data{' '}
        <Button ml="25px" onClick={getAllTableData}>
          Fetch All
        </Button>
      </Heading>
      <Box className={styles.sample_data}>
        {loading ? (
          <Center h="200px">
            <Spinner />
          </Center>
        ) : importConfig?.filesMeta ? (
          Object.values(importConfig?.filesMeta)
            .sort((a, b) => a.uploadedAt.seconds - b.uploadedAt.seconds)
            .map((fileData: FsImportFileMeta, index) => {
              return (
                <Box pb="50px" pr="10px" key={index}>
                  <Heading size="sm">
                    {fileData.name}{' '}
                    <IconButton
                      key={`icon-link-${index}`}
                      size="xs"
                      variant="ghost"
                      onClick={() => {
                        window.open(`${process.env.REACT_APP_BIG_QUERY_LINK + fileData.name}`, '_blank');
                      }}
                      aria-label="BigQuery Link"
                      icon={<LinkIcon />}
                    />
                    <IconButton
                      variant="ghost"
                      icon={<DeleteIcon />}
                      onClick={() => {
                        if (importConfig?.filesMeta) {
                          setSelectedFile(fileData.name);
                          onDeleteOpen();
                        }
                      }}
                      aria-label={'Delete file'}
                    ></IconButton>
                  </Heading>
                  <Flex>
                    <Text fontSize="xs">Uploaded At: {convertToLocalDayAndTime(fileData.uploadedAt)}</Text>
                    <Text fontSize="xs" pl="25px">
                      Row Count: {fileData.recordCount}
                    </Text>
                  </Flex>
                  {showDataForTable(fileData.name, hasCutomHeaders && index === 0 && onEditStep)}
                </Box>
              );
            })
        ) : (
          <Center h="200px">
            <Text>No files available</Text>
          </Center>
        )}
      </Box>
      <DistinctValuesModal
        isOpen={isOpen}
        onClose={onClose}
        table={distinctValues?.table}
        column={distinctValues?.column}
        dataHeader={distinctValues?.dataHeader}
      />
      <DeleteFileModal
        importConfig={importConfig}
        selectedFile={selectedFile}
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
      />
    </Stack>
  );
};

export default SampleData;

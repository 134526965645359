import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  VStack,
  Input,
  Alert,
  AlertIcon,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Heading,
  Checkbox,
  Center,
  Card,
  CardHeader,
  CardBody,
  Code,
  StackDivider,
  Container,
  Flex,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import React from 'react';
import { SetStateAction, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { auth } from '../../services/firebase';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { StyledFileSelectField } from './StyledFileSelectField';
import { MoveStudentIds } from '../../models/ChangeStudentId';
import { getErrorMessages } from '../../services/utils';

const MAX_FILE_SIZE = 32000000; // about 32MB
const csvFileTypes = ['text/csv', 'application/csv'];

interface UploadFileValues {
  file: File | undefined;
}

export const ChangeStudentId: React.FC = () => {
  const [inputProgramId, setInputProgramId] = useState('');
  const handleInputChangeProgramid = (e: { target: { value: SetStateAction<string> } }) =>
    setInputProgramId(e.target.value);
  const isProgramError = inputProgramId === '';

  const validationSchema = Yup.object().shape({
    file: Yup.mixed<File>()
      .required('The CSV file is required')
      .test('fileSize', 'File too large', (value) => value.size < MAX_FILE_SIZE)
      .test('fileFormat', 'The file should be a csv file', (value) => csvFileTypes.includes(value.type as string)),
  });

  const initialFormValues: UploadFileValues = {
    file: undefined,
  };

  const [ignoreConflicts, setIgnoreConflicts] = useState<boolean>(false);
  const [commit, setCommit] = useState<boolean>(false);

  const [error, setError] = useState<string>();
  const [searchResult, setSearchResult] = useState<MoveStudentIds>();
  const [isSearching, setIsSearching] = useState(false);
  const runSearch = async (values: UploadFileValues) => {
    try {
      setError(undefined);
      setSearchResult(undefined);

      if (!values.file) {
        return;
      }

      const file = values.file;
      const formData = new FormData();
      const blob = file.slice(0, file.size, 'text/csv');
      const newFile = new File([blob], `${file.name}.csv`, { type: 'text/csv' });
      formData.append('file', newFile);

      const token = await auth.currentUser?.getIdToken();
      if (token) {
        setIsSearching(true);
        const res = await axios.post(`${process.env.REACT_APP_JOBS_BASE_URL}csa/admin/studentids`, formData, {
          headers: { Authorization: `Bearer ${token}` },
          params: { ignoreConflicts, pid: inputProgramId, dryRun: !commit },
        });
        setSearchResult(res.data);
        setIsSearching(false);
      }
    } catch (error) {
      const e = error as AxiosError;
      setError(getErrorMessages(e));
      setIsSearching(false);
      console.error(error);
    }
  };

  return (
    <Flex>
      <Card>
        <CardHeader>
          <Heading>Change Rating Student ID</Heading>
        </CardHeader>
        <CardBody>
          <VStack spacing={8} divider={<StackDivider />}>
            <Container>
              <Text>
                To change the Student ID of historical ratings, the new student must be rostered with their new student
                id.
              </Text>
              <br />
              <Text>
                Create a CSV file with each line identifying the ratings to be updated. The first line of the file must
                contain the following headers.
              </Text>
              <br />
              <Code>old_student_id,current_student_id,rating_id</Code>
            </Container>
            <Container>
              <Grid templateColumns="repeat(2, 1fr)" templateRows="repeat(3, 1fr)">
                <GridItem colSpan={2}>
                  <FormControl isInvalid={isProgramError} isRequired>
                    <FormLabel>Program Id</FormLabel>
                    <Input value={inputProgramId} onChange={handleInputChangeProgramid} />
                    {!isProgramError ? (
                      <FormHelperText>The program where ratings will be updated.</FormHelperText>
                    ) : (
                      <FormErrorMessage>Program Id is required.</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <Checkbox isChecked={ignoreConflicts} onChange={(e) => setIgnoreConflicts(e.target.checked)}>
                      Ignore Student ID Conflicts
                    </Checkbox>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <Checkbox isChecked={commit} onChange={(e) => setCommit(e.target.checked)}>
                      Commit Changes
                    </Checkbox>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <Formik
                    enableReinitialize
                    initialValues={initialFormValues}
                    validationSchema={validationSchema}
                    onSubmit={runSearch}
                  >
                    <Form>
                      <StyledFileSelectField name="file" disabled={isSearching} />
                      <Button isLoading={isSearching} colorScheme="blue" type="submit">
                        Upload CSV
                      </Button>
                    </Form>
                  </Formik>
                </GridItem>
              </Grid>
            </Container>
            {searchResult ? (
              <Container>
                <Center>
                  {searchResult.ratingsFound} of {searchResult.ratingsRequested} ratings{' '}
                  {searchResult.dryRun ? 'may be' : 'have been'} updated.
                </Center>
                <Center>
                  {searchResult.problems.length} of {searchResult.ratingsRequested} ratings had problems.
                </Center>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      {searchResult?.problems.length ? (
                        <Tr>
                          <Th>Problem</Th>
                        </Tr>
                      ) : null}
                    </Thead>
                    <Tbody>
                      {searchResult.problems.map((rating, index) => {
                        return (
                          <Tr key={index}>
                            <Td>{rating}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Container>
            ) : null}
            {error ? (
              <Container>
                <Alert status="error" variant="subtle">
                  <AlertIcon />
                  <Text>{error}</Text>
                </Alert>
              </Container>
            ) : null}
          </VStack>
        </CardBody>
      </Card>
    </Flex>
  );
};

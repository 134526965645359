import './index.css';

import { StrictMode } from 'react';

import App from 'components/App/App';
import { Exports } from 'components/Exports/Exports';
import { ChangeRaterEmail } from 'components/Tools/ChangeRaterEmail';
import { ChangeStudentId } from 'components/Tools/ChangeStudentId';
import { ChangeRatingSite } from 'components/Tools/ChangeRatingSite';
import { ChangeAssignmentPeriod } from 'components/Tools/ChangeAssignmentPeriod';
import Import from 'components/Imports/Import/Import';
import ColumnMapping from 'components/Imports/Import/MergeStrategy/ColumnMapping/ColumnMapping';
import DefineMergeStrategy from 'components/Imports/Import/MergeStrategy/DefineMergeStrategy/DefineMergeStrategy';
import FileMapping from 'components/Imports/Import/MergeStrategy/FileMapping/FileMapping';
import Filter from 'components/Imports/Import/MergeStrategy/Filter/Filter';
import Verify from 'components/Imports/Import/MergeStrategy/Verify/Verify';
import Imports from 'components/Imports/Imports';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ChakraBaseProvider, extendBaseTheme } from '@chakra-ui/react';
import chakraTheme from '@chakra-ui/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const theme = extendBaseTheme({
  ...chakraTheme,
  components: {
    ...chakraTheme.components,
  },
});

export default function ErrorPage() {
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Imports />,
      },
      {
        path: 'import/:importId',
        element: <Import />,
        children: [
          {
            path: '',
            element: <DefineMergeStrategy />,
          },
          {
            path: 'file-mapping',
            element: <FileMapping />,
          },
          {
            path: 'column-mapping',
            element: <ColumnMapping />,
          },
          {
            path: 'filter',
            element: <Filter />,
          },
          {
            path: 'verify',
            element: <Verify />,
          },
        ],
      },
      {
        path: 'exports',
        element: <Exports />,
      },
      {
        path: 'rateremail',
        element: <ChangeRaterEmail />,
      },
      {
        path: 'ratingstudentid',
        element: <ChangeStudentId />,
      },
      {
        path: 'ratingsite',
        element: <ChangeRatingSite />,
      },
      {
        path: 'ratingassignmentperiod',
        element: <ChangeAssignmentPeriod />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraBaseProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraBaseProvider>
    </QueryClientProvider>
  </StrictMode>,
);

import { useEffect, useState } from 'react';

import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { FsImportConfig } from 'models/ImportConfig';
import { useNavigate } from 'react-router-dom';
import { db } from 'services/firebase';
import { convertToLocalDayAndTime } from 'services/utils';
import { InfoIcon } from '@chakra-ui/icons';

import {
  Flex,
  FormLabel,
  Input,
  Select,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
} from '@chakra-ui/react';

import styles from './Imports.module.scss';

const importCollections = 'importConfigs';

const Imports = () => {
  const navigate = useNavigate();
  const [importList, setImports] = useState<FsImportConfig[]>([]);
  const [importListMemory, setImportsMemory] = useState<FsImportConfig[]>([]);

  useEffect(() => {
    getDocs(query(collection(db, importCollections), orderBy('name'))).then((querySnapshot) => {
      const importCollection = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as FsImportConfig));
      setImports(importCollection);
      setImportsMemory(importCollection);
    });
  }, []);

  const search = (value: string) => {
    const filteredList = importListMemory.filter((x) => {
      return (
        x.name?.toLowerCase().includes(value.toLowerCase()) || x.hubspotId?.toLowerCase().includes(value.toLowerCase())
      );
    });
    setImports(filteredList);
  };

  const filter = (value: string) => {
    if (!value || value === 'all') {
      setImports(importListMemory);
    } else {
      const filteredList = importListMemory.filter((x) => {
        if (value === 'running') {
          return x.lastRun?.status.toLowerCase() === 'building' || x.lastRun?.status.toLowerCase() === 'processing';
        }
        if (value === 'paused') {
          return x.pausedAt;
        }
        return x.lastRun?.status.toLowerCase() === value.toLowerCase();
      });
      setImports(filteredList);
    }
  };

  return (
    <>
      <Flex>
        <Input
          placeholder="Search by Hubspot Id or Name"
          size="md"
          width="400px"
          onChange={(v) => {
            search(v.target.value);
          }}
        />
        <Spacer />
        <>
          <FormLabel>Filter by Status: </FormLabel>
          <Select
            width="200px"
            onChange={(v) => {
              filter(v.target.value);
            }}
          >
            <option value="all">All</option>
            <option value="paused">Paused</option>
            <option value="running">Running</option>
            <option value="completed">Completed</option>
            <option value="failed">Failed</option>
          </Select>
        </>
      </Flex>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Hubspot Id</Th>
              <Th>Last Import Status</Th>
              <Th>Last Successful Import</Th>
            </Tr>
          </Thead>
          <Tbody>
            {importList.map((importItem, index) => {
              const importFailed = Boolean(importItem.lastRun?.error);
              return (
                <Tr key={index}>
                  <Td
                    className={styles.link}
                    onClick={() => {
                      navigate(`/import/${importItem.id}`);
                    }}
                    color="blue.500"
                    textDecoration="underline"
                  >
                    {importItem.name}
                  </Td>
                  <Td>{importItem.hubspotId ?? '--'}</Td>
                  <Td display="flex" alignItems="center">
                    {importItem.lastRun ? importItem.lastRun.status : '--'}{' '}
                    {importFailed && (
                      <Tooltip label={importItem.lastRun?.error as string}>
                        <InfoIcon boxSize={5} ml="15px" />
                      </Tooltip>
                    )}
                  </Td>
                  <Td>
                    {importItem.lastSuccessfulRunAt ? convertToLocalDayAndTime(importItem.lastSuccessfulRunAt) : '--'}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Imports;

import { useState } from 'react';

import {
  Button, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Textarea,
} from '@chakra-ui/react';

import { NoteModalData } from '../types';

const FilterNoteModal = ({ isOpen, onClose, onUpdate, noteModalData }: { isOpen: boolean, onClose: () => void, onUpdate: (noteValue: string | undefined) => void, noteModalData: NoteModalData; }) => {
  const [filterNote, setFilterNote] = useState<string | undefined>(undefined);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Attach Note</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormLabel>
            Note
            <Textarea h='300px' placeholder='Note' defaultValue={noteModalData?.filter?.note} onChange={(v) => setFilterNote(v.target.value)} />
          </FormLabel>
        </ModalBody>

        <ModalFooter>
          <Button variant='ghost' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme='blue' onClick={() => { onUpdate(filterNote); onClose(); }}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FilterNoteModal;
import { deleteDoc, doc } from 'firebase/firestore';
import { ExportConfig, exportTypes } from 'models/ExportConfig';
import { db } from 'services/firebase';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

const DeleteExportModal = ({
  isOpen,
  onClose,
  exportConfig,
}: {
  isOpen: boolean;
  onClose: () => void;
  exportConfig: ExportConfig | undefined;
}) => {
  const deleteExport = async () => {
    if (exportConfig) {
      await deleteDoc(doc(db, 'exportConfigs', exportConfig.id));
    }
    onClose();
  };

  const exportType = exportTypes.find((ex) => ex.value === exportConfig?.export.type)?.label;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Export</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Are you sure you want to delete the {exportType} for “{exportConfig?.org.name}”?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button colorScheme="blue" onClick={deleteExport}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteExportModal;

import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { SearchAssignmentPeriod } from './SearchAssignmentPeriod';
import { UpdateAssignmentPeriod } from './UpdateAssignmentPeriod';
import { MoveRatingPeriods } from 'models/ChangeAssignmentPeriod';
import { ValidationSchema } from 'models/ChangeRatingSite';

const MAX_FILE_SIZE = 32000000; // about 32MB
const csvFileTypes = ['text/csv'];

export const ChangeAssignmentPeriod: React.FC = () => {
  const [programId, setProgramId] = useState('');
  const [ratingPeriod, setRatingPeriod] = useState('');
  const [file, setFile] = useState<File | undefined>(undefined);
  const [error, setError] = useState<string>();
  const [searchResult, setSearchResult] = useState<MoveRatingPeriods>();
  const [isSearching, setIsSearching] = useState(false);

  const handleInputChangeProgramid = (e: React.ChangeEvent<HTMLInputElement>) => setProgramId(e.target.value);
  const isProgramError = programId === '';

  const validationSchema: Yup.ObjectSchema<ValidationSchema> = Yup.object().shape({
    file: Yup.mixed<File>()
      .required('The CSV file is required')
      .test('fileSize', 'File too large', (value) => value && value.size < MAX_FILE_SIZE)
      .test('fileFormat', 'The file should be a csv file', (value) => value && csvFileTypes.includes(value.type)),
  });

  const handleSearchResult = (result: MoveRatingPeriods) => {
    setSearchResult(result);
  };

  return (
    <>
      <Flex>
        <Card>
          <CardHeader>
            <Heading>Change Rating Assignment Period</Heading>
          </CardHeader>
          <CardBody>
            <Container>
              <Grid templateColumns="repeat(1, 1fr)" gap={6}>
                <GridItem colSpan={2}>
                  <FormControl isInvalid={isProgramError} isRequired>
                    <FormLabel>Program Id</FormLabel>
                    <Input value={programId} onChange={handleInputChangeProgramid} />
                    {!isProgramError ? (
                      <FormHelperText>The program where ratings will be updated.</FormHelperText>
                    ) : (
                      <FormErrorMessage>Program Id is required.</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <SearchAssignmentPeriod
                    programId={programId}
                    setProgramId={setProgramId}
                    file={file}
                    setFile={setFile}
                    setSearchResult={handleSearchResult}
                    setError={setError}
                    setIsSearching={setIsSearching}
                    validationSchema={validationSchema}
                    isSearching={isSearching}
                  />
                </GridItem>

                {searchResult && file && (
                  <UpdateAssignmentPeriod
                    programId={programId}
                    ratingPeriod={ratingPeriod}
                    setRatingPeriod={setRatingPeriod}
                    file={file}
                    searchResult={searchResult}
                    setError={setError}
                    setIsSearching={setIsSearching}
                    isSearching={isSearching}
                    setSearchResult={handleSearchResult}
                  />
                )}
              </Grid>
            </Container>
          </CardBody>
        </Card>
      </Flex>

      {error && <div>{error}</div>}
    </>
  );
};

import { useEffect, useRef, useState } from 'react';

import { deleteField, doc, FieldValue, getDoc, updateDoc } from 'firebase/firestore';
import { FsImportConfig, FsImportFileMeta, FsImportTableAliasConfig, ImportFileStrategy } from 'models/ImportConfig';
import { useParams } from 'react-router-dom';
import { db } from 'services/firebase';

import { AddIcon, AttachmentIcon, DeleteIcon, InfoIcon, WarningIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Heading,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import FileDropDown from '../FileDropdown/FileDropdown';
import WizardFooter from '../WizardFooter/WizardFooter';
import AliasNoteModal from './AliasNoteModal/AliasNoteModal';
import styles from './DefineMergeStrategy.module.scss';
import { FirestoreUtil } from 'services/utils';

const columnWidth = '450px';
const aliasRegex = new RegExp('^[A-Za-z0-9-_]+$');

const DefineMergeStrategy = () => {
  const { importId } = useParams();
  const [importConfig, setImportConfig] = useState<FsImportConfig | undefined>(undefined);
  const [aliasRows, setAliasRows] = useState<FsImportTableAliasConfig[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [aliasRowForNote, setAliasRowForNote] = useState<
    { index: number; aliasConfig: FsImportTableAliasConfig } | undefined
  >(undefined);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: alertIsOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const [changeRequested, setChangeRequested] = useState<string>('');
  const [formValue, setFormValue] = useState<ImportFileStrategy | string | undefined>();
  const cancelRef = useRef(null);

  const handleChangeMergeStrategyClick = (value: ImportFileStrategy) => {
    onAlertOpen();
    setFormValue(value);
    setChangeRequested('changeMergeStrategy');
  };

  const handleChangeCustomHeaderClick = (value: string) => {
    onAlertOpen();
    setFormValue(value);
    setChangeRequested('changeCustomHeader');
  };

  const handleContinueClick = () => {
    onAlertClose();
    if (changeRequested === 'changeMergeStrategy') {
      changeMergeStrategy(formValue as ImportFileStrategy);
    } else {
      changeCustomHeader(formValue);
    }
  };

  const changeMergeStrategy = async (value: ImportFileStrategy | undefined) => {
    try {
      const newConfig = { filesConfig: { ...importConfig?.filesConfig, strategy: value } };
      const flattenedNewConfig = FirestoreUtil.toDotNotation(newConfig);
      if (value === 'join' && newConfig?.filesConfig?.columns) {
        flattenedNewConfig['filesConfig.columns'] = deleteField();
      }
      flattenedNewConfig['filesConfig.tables'] = deleteField();
      flattenedNewConfig['filesConfig.fileMaps'] = deleteField();
      flattenedNewConfig['columnMaps'] = deleteField();
      flattenedNewConfig['sourceFilters'] = deleteField();
      await updateDoc(
        doc(db, `/importConfigs/${importId}`),
        flattenedNewConfig as { [x: string]: FieldValue | Partial<unknown> | undefined },
      );
      getImportConfig();
    } catch (e) {
      console.error(e);
    }
  };

  const changeCustomHeader = async (value: string | undefined) => {
    try {
      const newConfig = { ...importConfig?.filesConfig };
      if (value === 'yes' && importConfig?.filesMeta && Object.keys(importConfig.filesMeta).length > 0) {
        newConfig.columns = Object.values(importConfig.filesMeta)[0].columns?.map(() => '');
      } else {
        newConfig.columns = deleteField() as unknown as string[];
      }
      const body = {
        filesConfig: { ...newConfig },
        columnMaps: deleteField(),
        sourceFilters: deleteField(),
      };
      const flattenedBody = FirestoreUtil.toDotNotation(body) as {
        [x: string]: FieldValue | Partial<unknown> | undefined;
      };
      await updateDoc(doc(db, `/importConfigs/${importId}`), flattenedBody);
      getImportConfig();
    } catch (e) {
      console.error(e);
    }
  };

  const getImportConfig = () => {
    getDoc(doc(db, `/importConfigs/${importId}`)).then((querySnapshot) => {
      const data = querySnapshot.data() as FsImportConfig;
      setImportConfig(data);
      setAliasRows(data.filesConfig?.tables || []);
    });
  };

  const verify = async () => {
    if (!importConfig?.filesConfig?.strategy) {
      return Promise.reject('A Strategy needs to be selected to move forward');
    }
    if (errors.length > 0) {
      return Promise.reject('');
    }
    return Promise.resolve();
  };

  const addAliasRow = () => {
    setAliasRows([...aliasRows, { alias: '' }]);
  };

  const removeAliasRow = (index: number) => {
    const newAliasRows = [...aliasRows];
    newAliasRows.splice(index, 1);
    setAliasRows(newAliasRows);
    saveAliases(newAliasRows);
  };

  const saveAliases = async (aliases: FsImportTableAliasConfig[]) => {
    setErrors([]);
    const errors = [] as string[];
    const tablesAliases = aliases
      .filter((x) => x.alias && x.sourceTable)
      .map((x) => {
        return x;
      });
    if (tablesAliases && tablesAliases.length > 0) {
      tablesAliases.forEach((table) => {
        if (table.alias) {
          const res = aliasRegex.test(table.alias);
          if (!res) {
            errors.push(`${table.alias} has invalid characters.`);
          }
          if (importConfig?.filesMeta) {
            Object.values(importConfig?.filesMeta).forEach((x) => {
              if (x.name === table.alias) {
                errors.push(`${table.alias} is already in use.`);
              }
            });
          }
        }
        const duplicates = tablesAliases.filter((x) => x.alias === table.alias);
        if (duplicates.length > 1) {
          errors.push(`${table.alias} is already in use.`);
        }
      });
    }
    if (errors.length === 0) {
      await updateDoc(doc(db, `/importConfigs/${importId}`), {
        filesConfig: { ...importConfig?.filesConfig, tables: tablesAliases },
      });
    } else {
      const newErrors = [...new Set(errors)];
      setErrors(newErrors);
    }
  };

  const editRow = (editIndex: number, field: string, value: string | undefined) => {
    const newAliasRows = aliasRows.map((aliasRow, index) => {
      if (index === editIndex) {
        return { ...aliasRow, [field]: value };
      } else {
        return aliasRow;
      }
    });
    setAliasRows(newAliasRows);
    saveAliases(newAliasRows);
  };

  useEffect(() => {
    getImportConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Heading as="h2" size="md">
        File Merge Strategy
      </Heading>
      <Text fontSize="xs">How should files be combined?</Text>
      <Box pt="15px" pb="50px">
        <Flex>
          <RadioGroup onChange={handleChangeMergeStrategyClick} value={importConfig?.filesConfig?.strategy}>
            <Stack direction="row">
              <Card bg="grey" variant="outline">
                <Radio size="lg" pl="15px" value="stack">
                  <Card borderLeftRadius="0px" variant="unstyled" ml="5px" p="20px" w="250px" bg="white">
                    <Stack>
                      <Text>Stacked</Text>
                      <Text fontSize="xs">
                        One or more files that share a common set of columns and should be unioned together.
                      </Text>
                    </Stack>
                  </Card>
                </Radio>
              </Card>
              <Spacer />
              <Card bg="grey" variant="outline">
                <Radio size="lg" pl="15px" value="join">
                  <Card h="100%" borderLeftRadius="0px" variant="unstyled" ml="5px" p="20px" w="250px" bg="white">
                    <Stack>
                      <Text>Joined</Text>
                      <Text fontSize="xs">
                        Two or more files with different sets of columns that should be joined together row to row.
                      </Text>
                    </Stack>
                  </Card>
                </Radio>
              </Card>
            </Stack>
          </RadioGroup>
        </Flex>
      </Box>
      {importConfig?.filesConfig?.strategy === 'stack' ? (
        <>
          <Heading as="h2" size="md">
            Custom Header
          </Heading>
          <Text fontSize="sm">Do the files need to have a header row added?</Text>
          <Box pt="15px" pb="50px">
            <Flex>
              <RadioGroup
                onChange={handleChangeCustomHeaderClick}
                value={importConfig?.filesConfig?.columns ? 'yes' : 'no'}
              >
                <Stack direction="row">
                  <Radio size="lg" pl="15px" value="yes">
                    <Text>Yes</Text>
                  </Radio>
                  <Spacer />
                  <Radio size="lg" pl="15px" value="no">
                    <Text>No</Text>
                  </Radio>
                </Stack>
              </RadioGroup>
            </Flex>
          </Box>
          <Card colorScheme="cyan" variant="filled">
            <CardBody>
              <Flex>
                <Center>
                  <InfoIcon boxSize={5} aria-label="Custom Header Info" />
                </Center>
                <Text pl="15px">
                  Edit the header names on the top sample table in order to define a custom header. All headers need to
                  be unique or the query will fail.
                </Text>
              </Flex>
            </CardBody>
          </Card>
        </>
      ) : null}
      {importConfig?.filesConfig?.strategy === 'join' ? (
        <>
          <Heading as="h2" size="md">
            Table Aliases
          </Heading>
          <Text fontSize="sm">
            A table alias allowed you to reference the same table by diferent names. This is useful when a single table
            includes different data types (e.g. students and teachers).
          </Text>
          <Card colorScheme="cyan" variant="filled" mt="15px" mb="15px">
            <CardBody>
              <Flex direction="row">
                <Center>
                  <InfoIcon boxSize={5} aria-label="Table Alias Info" />
                </Center>
                <Flex direction="column">
                  <Text pl="15px">
                    Consider adding a pre-mapping filter for the alias and the original. <br /> An alias can contain the
                    following characters: A-Z, a-z, 0-9, -, _
                  </Text>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
          <Box pt="15px" pb="50px">
            <Flex pt="50px" direction="column">
              <Flex direction="row" pb="15px">
                <Box w={columnWidth} pr="15px">
                  <Text fontWeight="bold">File</Text>
                </Box>
                <Box w={columnWidth} pr="15px">
                  <Text fontWeight="bold">Alias</Text>
                </Box>
                <Box w="100px">
                  <Spacer />
                </Box>
              </Flex>
              {aliasRows.map((aliasRow, index) => {
                if (importConfig?.filesMeta) {
                  return (
                    <Flex direction="row" key={`AliasRow ${index + JSON.stringify(aliasRow)}`} pb="15px">
                      <Box w={columnWidth} pr="15px">
                        <FileDropDown
                          value={aliasRow.sourceTable}
                          fileList={
                            ((Object.values(importConfig?.filesMeta) || []) as FsImportFileMeta[]).map(
                              (fileMeta: FsImportFileMeta) => ({
                                name: fileMeta.name,
                              }),
                            ) as { name: string }[]
                          }
                          onChange={(v: string) => {
                            editRow(index, 'sourceTable', v);
                          }}
                        ></FileDropDown>
                      </Box>
                      <Box w={columnWidth} pr="15px">
                        <Input
                          minW="100px"
                          defaultValue={aliasRow.alias}
                          onBlurCapture={(element) => {
                            editRow(index, 'alias', element.target.value);
                          }}
                        />
                      </Box>
                      <Flex w="100px" direction="row" pr="15px">
                        <Box pr="15px">
                          <div className={styles.notification_container}>
                            <IconButton
                              variant="ghost"
                              onClick={() => {
                                setAliasRowForNote({ index, aliasConfig: aliasRow });
                                onOpen();
                              }}
                              icon={<AttachmentIcon />}
                              aria-label={'Add Note for Alias Row'}
                            ></IconButton>
                            {aliasRow.note ? <span className={styles.notification_icon}></span> : <></>}
                          </div>
                        </Box>
                        <Box>
                          <IconButton
                            variant="ghost"
                            onClick={() => {
                              removeAliasRow(index);
                            }}
                            icon={<DeleteIcon />}
                            aria-label={'Remove Alias Row'}
                          ></IconButton>
                        </Box>
                      </Flex>
                    </Flex>
                  );
                }
                return null;
              })}
            </Flex>
            <Button mt="25px" leftIcon={<AddIcon />} variant="ghost" onClick={addAliasRow} aria-label="Add table Alias">
              {' '}
              Add table Alias
            </Button>
          </Box>
          <AliasNoteModal
            isOpen={isOpen}
            onClose={onClose}
            aliasRow={aliasRowForNote?.aliasConfig}
            onUpdate={(note: string | undefined) => {
              if (aliasRowForNote) {
                editRow(aliasRowForNote?.index, 'note', note);
              }
            }}
          />
        </>
      ) : null}
      {errors.length > 0 ? (
        <Card colorScheme="red" variant="filled" mb="10px">
          <CardBody>
            {errors.map((error, index) => (
              <Flex key={index}>
                <Center>
                  <WarningIcon boxSize={5} aria-label="Column Mappings Errors" />
                </Center>
                <Text pl="15px">{error}</Text>
              </Flex>
            ))}
          </CardBody>
        </Card>
      ) : null}
      <WizardFooter save={verify} />
      <AlertDialog isOpen={alertIsOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Configuration change
            </AlertDialogHeader>

            <AlertDialogBody>
              Making this change will remove downstream configurations that depend on it. Would you like to proceed?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleContinueClick} ml={3}>
                Continue
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DefineMergeStrategy;

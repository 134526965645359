import { db } from 'services/firebase';
import { doc, updateDoc, deleteField } from 'firebase/firestore';
import { FsImportConfig } from 'models/ImportConfig';
import { useParams } from 'react-router-dom';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

const DeleteFileModal = ({
  isOpen,
  onClose,
  selectedFile,
  importConfig,
}: {
  isOpen: boolean;
  onClose: () => void;
  selectedFile: string;
  importConfig: FsImportConfig | null;
}) => {
  const { importId } = useParams();
  const removeFile = async (selectedFile: string) => {
    if (importConfig?.filesMeta) {
      const fileArray = Object.values(importConfig?.filesMeta);
      if (fileArray.length < 2) {
        const update = { filesMeta: deleteField() };
        await updateDoc(doc(db, `/importConfigs/${importId}`), update);
        return;
      }
      const fieldPath = `filesMeta.${selectedFile}`;
      const update = { [fieldPath]: deleteField() };
      await updateDoc(doc(db, `/importConfigs/${importId}`), update);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete File</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{`Are you sure you want to delete the file ${selectedFile}?`}</Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              removeFile(selectedFile);
              onClose();
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteFileModal;

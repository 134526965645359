import { useEffect, useState } from 'react';

import { FsImportConfig, FsImportFileMeta } from 'models/ImportConfig';

import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Container,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react';

import { FsImportColumnMapVM } from '../types';

const rowHeight = '300px';

const ColumnExpressionModal = ({
  isOpen,
  onClose,
  onUpdate,
  columnMap,
  importConfig,
}: {
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (data: FsImportColumnMapVM | undefined) => void;
  columnMap: FsImportColumnMapVM | undefined;
  importConfig: FsImportConfig | null;
}) => {
  const [columnExpressionValue, setColumnExpressionValue] = useState<string | undefined>(undefined);
  const [noteValue, setNoteValue] = useState<string | undefined>(undefined);
  const [fileAndColumnValues, setFileAndColumnValues] = useState<string[]>([]);
  const [hasCustomHeaders, setHasCustomHeaders] = useState<boolean>(false);

  useEffect(() => {
    setHasCustomHeaders(!!(importConfig?.filesConfig?.columns && importConfig?.filesConfig?.columns.length > 0));
    let fileAndColumn = [] as string[];
    setColumnExpressionValue(columnMap?.sourceExpression);
    setNoteValue(columnMap?.note);
    if (importConfig?.filesConfig?.strategy === 'stack') {
      if (hasCustomHeaders) {
        fileAndColumn = importConfig.filesConfig.columns || [];
      } else {
        if (importConfig?.filesMeta) {
          fileAndColumn = Object.values(importConfig.filesMeta)[0].columns;
        }
      }
    } else {
      if (importConfig?.filesMeta) {
        Object.values(importConfig?.filesMeta).forEach((fileMeta: FsImportFileMeta) => {
          fileMeta.columns.forEach((column) => fileAndColumn.push('`' + fileMeta.name + '`.`' + column + '`'));
        });
      }
    }
    setFileAndColumnValues(fileAndColumn.filter((x) => !!x));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importConfig, columnMap]);

  const update = () => {
    if (columnMap) {
      onUpdate({ ...columnMap, sourceExpression: columnExpressionValue, note: noteValue });
      onClose();
    } else {
      onUpdate(undefined);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Expression Editor</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container>
            {importConfig?.filesConfig?.strategy === 'stack' ? (
              <Card colorScheme="cyan" variant="filled" mt="15px" mb="15px">
                <CardBody>
                  <Flex direction="row">
                    <Center>
                      <InfoIcon boxSize={5} aria-label="Column Expression Info" />
                    </Center>
                    <Flex direction="column">
                      <Text pl="15px">
                        Don&apos;t prefix columns with table names since the strategy is &quot;stacked&quot;.
                      </Text>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            ) : null}
            <Flex>
              <Box w="50%">
                <FormLabel>Column Expression</FormLabel>
                <Textarea
                  h={rowHeight}
                  placeholder="Column Expression"
                  value={columnExpressionValue}
                  onChange={(v) => setColumnExpressionValue(v.target.value)}
                />
              </Box>
              <Box w="50%">
                <FormLabel ml="25px">Available Columns</FormLabel>
                <Card p="10px" variant="outline" ml="25px" w="250px" h={rowHeight} overflowY="scroll">
                  {importConfig?.filesConfig?.strategy === 'stack' ? (
                    <Box>
                      {fileAndColumnValues.map((fileColumnValue, index) => (
                        <Text size="sm" key={index}>
                          `{fileColumnValue}`
                        </Text>
                      ))}
                    </Box>
                  ) : (
                    <Box>
                      {fileAndColumnValues.map((fileColumnValue, index) => (
                        <Text size="sm" key={index}>
                          {fileColumnValue}
                        </Text>
                      ))}
                    </Box>
                  )}
                </Card>
              </Box>
            </Flex>
            <Flex>
              <Box w="100%">
                <FormLabel pt="15px">Note</FormLabel>
                <Textarea
                  w="100%"
                  h="100px"
                  placeholder="Note"
                  value={noteValue}
                  onChange={(v) => setNoteValue(v.target.value)}
                />
              </Box>
            </Flex>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              update();
            }}
          >
            Save and Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ColumnExpressionModal;

import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { addDoc, collection, doc, Timestamp, updateDoc, deleteField } from 'firebase/firestore';
import { ExportConfig, exportTypes, frequencyTypes, Org } from 'models/ExportConfig';
import ReactSelect, { MultiValue, SingleValue } from 'react-select';
import { auth, db } from 'services/firebase';
import { Card, CardBody, Center, Flex, FormControl, FormErrorMessage, FormHelperText, Text } from '@chakra-ui/react';
import {
  assessmentTypes,
  convertToLocalDayAndTime,
  getPausedStatus,
  dayOfWeekOptions,
  dayOfMonthOptions,
} from 'services/utils';

import {
  Box,
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Switch,
  Textarea,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

const AddEditExportModal = ({
  isOpen,
  onClose,
  exportConfig,
  setSelectedExport,
}: {
  isOpen: boolean;
  onClose: () => void;
  exportConfig?: ExportConfig;
  setSelectedExport: (exportConfig: ExportConfig | undefined) => void;
}) => {
  const hubspotId = exportConfig?.org.hubSpotId ?? exportConfig?.org.hubspotId ?? undefined;
  const [organizations, setOrganizations] = useState<{ value: Org; label: string }[] | undefined>(undefined);
  const [programs, setPrograms] = useState<{ value: string; label: string }[] | undefined>(undefined);
  const [organization, setOrganization] = useState<SingleValue<{ value: Org; label: string }> | undefined>(
    exportConfig ? { value: exportConfig.org, label: `${exportConfig.org.name} (${hubspotId})` } : undefined,
  );
  const selectedProgs = programs?.length
    ? exportConfig?.export.programIds?.map((id) => {
        const program = programs.find((program) => program.value === id);
        return { value: program ? program.value : '', label: program ? program.label : '' };
      })
    : undefined;
  const [selectedPrograms, setSelectedPrograms] = useState<MultiValue<{ value: string; label: string }> | undefined>(
    selectedProgs,
  );
  const [exportType, setExportType] = useState<string | undefined>(exportConfig ? exportConfig.export.type : undefined);
  const [frequencyType, setFrequencyType] = useState<string | undefined>(
    exportConfig ? exportConfig.frequency.type : undefined,
  );
  const [frequencyDay, setFrequencyDay] = useState<number | undefined>(
    exportConfig && typeof exportConfig.frequency.dayOfWeek === 'number'
      ? exportConfig.frequency.dayOfWeek
      : exportConfig?.frequency.dayOfMonth
      ? exportConfig.frequency.dayOfMonth
      : undefined,
  );
  const displayFrequencyDayValue =
    frequencyType === 'MONTHLY'
      ? dayOfMonthOptions.find((opt) => opt.value === frequencyDay)?.value
      : dayOfWeekOptions.find((opt) => opt.value === frequencyDay)?.value;
  const [fileName, setFilename] = useState<string | undefined>(exportConfig ? exportConfig.file.name : undefined);
  const [directory, setDirectory] = useState<string | undefined>(
    exportConfig ? exportConfig.file.directory : undefined,
  );
  const [delimiter, setDelimiter] = useState<string | undefined>(
    exportConfig ? exportConfig.file.delimiter : undefined,
  );
  const [note, setNote] = useState<string | undefined>(exportConfig?.note);
  const isPaused = getPausedStatus(exportConfig);
  const [automatedExport, setAutomatedExport] = useState<boolean>(!isPaused);
  const [error, setError] = useState('');
  const [selectedAssessmentTypes, setSelectedAssessmentTypes] = useState<string[] | undefined>(
    exportConfig ? exportConfig.export.assessmentTypeIds : undefined,
  );

  const handleClose = () => {
    setSelectedExport(undefined);
    onClose();
  };

  const save = async () => {
    setError('');
    if (!organization || !frequencyType || !exportType || !directory || !delimiter) {
      setError('A required field is missing.');
      return;
    }
    if (frequencyType !== 'WEEKDAYS' && frequencyDay == null) {
      setError('A required field is missing.');
      return;
    }
    const hubspotId = organization.value.hubSpotId ?? organization.value.hubspotId ?? undefined;
    const newExportConfig = {
      org: {
        id: organization.value.id,
        name: organization.value.name,
        hubspotId,
      } as unknown as Org,
      export: {
        type: exportType,
        programIds: selectedPrograms?.map((x) => x.value) ?? [],
        assessmentTypeIds: selectedAssessmentTypes ?? [],
      },
      frequency: {
        type: frequencyType,
      },
      file: {
        delimiter: delimiter,
        directory: directory,
      },
    } as ExportConfig;
    if (fileName) {
      newExportConfig.file.name = fileName;
    }
    if (note) {
      newExportConfig.note = note;
    } else {
      newExportConfig.note = '';
    }
    switch (frequencyType) {
      case 'WEEKLY':
        newExportConfig.frequency.dayOfWeek = frequencyDay;
        break;
      case 'MONTHLY':
        newExportConfig.frequency.dayOfMonth = frequencyDay;
        break;
      default:
        break;
    }
    if (!automatedExport && !exportConfig?.pausedAt) {
      newExportConfig.pausedAt = Timestamp.now();
    } else if (automatedExport && exportConfig?.pausedAt) {
      newExportConfig.pausedAt = deleteField() as Timestamp;
    }
    try {
      if (exportConfig) {
        await updateDoc(doc(db, 'exportConfigs', exportConfig.id), { ...newExportConfig });
      } else {
        await addDoc(collection(db, 'exportConfigs'), newExportConfig);
      }
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const afterOrgSelect = useCallback(
    (org: Org) => {
      auth.currentUser?.getIdToken().then((token) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}organizations/${org.id}/programs`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((result) => {
            const formattedData = (result.data.data as { name: string; id: string }[]).map((x) => ({
              label: x.name,
              value: x.id,
            }));
            setPrograms(formattedData);
            if (exportConfig?.export.programIds) {
              const selectedPrograms: { label: string; value: string }[] = [];
              exportConfig?.export.programIds.forEach((x) => {
                const result = formattedData.find((y) => y.value === x);
                if (result) {
                  selectedPrograms.push(result);
                }
              });
              setSelectedPrograms(selectedPrograms.map((x) => ({ value: x.value, label: x.label })));
            }
          });
      });
      if (org.hubSpotId) {
        setDirectory(`${org.hubSpotId}/sftp/results`);
      }
    },
    [exportConfig?.export.programIds],
  );

  useEffect(() => {
    auth.currentUser?.getIdToken().then((token) => {
      axios
        .get(`${process.env.REACT_APP_API_URL}organizations`, { headers: { Authorization: `Bearer ${token}` } })
        .then((result) => {
          const formattedData = (result.data.data as Org[]).map((x) => {
            const hubspotId = x.hubSpotId;
            return {
              label: `${x.name} (${hubspotId})`,
              value: x,
            };
          });
          setOrganizations(formattedData);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser]);

  useEffect(() => {
    if (exportConfig) {
      afterOrgSelect(exportConfig.org);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportConfig?.org.id]);

  const programsIdConcat = programs?.reduce((a, b) => `${a}${b}`, '');

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Export</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={2} spacing={5}>
            <FormControl isRequired>
              <FormLabel>Organization</FormLabel>
              <ReactSelect
                name="organization"
                isSearchable={true}
                defaultValue={organization}
                options={organizations}
                onChange={(v) => {
                  if (v) {
                    setOrganization(v);
                    afterOrgSelect(v?.value);
                  }
                }}
              ></ReactSelect>
            </FormControl>
            <FormControl>
              <FormLabel>Programs</FormLabel>
              <ReactSelect
                key={programsIdConcat}
                isMulti={true}
                options={programs}
                defaultValue={selectedPrograms}
                onChange={(v) => {
                  setSelectedPrograms(v);
                }}
              ></ReactSelect>
            </FormControl>
          </SimpleGrid>
          <FormControl isRequired w="100%" marginTop="15px" marginBottom="15px">
            <FormLabel>Directory</FormLabel>
            <Input
              name="directory"
              placeholder=""
              defaultValue={directory}
              onChange={(v) => setDirectory(v.target.value)}
            />
          </FormControl>
          <SimpleGrid columns={2} spacing={5}>
            <FormControl isRequired>
              <FormLabel>Delimiter</FormLabel>
              <Select
                defaultValue={delimiter}
                onChange={(v) => {
                  setDelimiter(v.target.value);
                }}
              >
                <option value="">Select a delimiter</option>
                <option value=",">Comma</option>
                <option value="|">Pipe</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>File Name</FormLabel>
              <Input defaultValue={fileName} onChange={(v) => setFilename(v.target.value)} />
              <FormHelperText>Include an extension.</FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Export Type</FormLabel>
              <Select
                defaultValue={exportType}
                onChange={(v) => {
                  setExportType(v.target.value);
                }}
              >
                <option value="">Select a type</option>
                {exportTypes?.map((x) => (
                  <option key={x.value} value={x.value}>
                    {x.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            {exportType?.includes('rating_export') ? (
              <FormControl>
                <FormLabel>Assessment Types</FormLabel>
                <ReactSelect
                  isMulti={true}
                  options={assessmentTypes}
                  defaultValue={selectedAssessmentTypes?.map((id) => {
                    const assessmentType = assessmentTypes.find((at) => at.value === id);
                    return {
                      value: assessmentType ? assessmentType.value : '',
                      label: assessmentType ? assessmentType.label : '',
                    };
                  })}
                  onChange={(values) => {
                    setSelectedAssessmentTypes(values.map((value) => value.value));
                  }}
                ></ReactSelect>
              </FormControl>
            ) : (
              <Box />
            )}
            <FormControl isRequired>
              <FormLabel>Frequency</FormLabel>
              <Select
                defaultValue={frequencyType}
                onChange={(v) => {
                  setFrequencyType(v.target.value);
                  setFrequencyDay(undefined);
                }}
              >
                <option value="">Select a frequency</option>
                {frequencyTypes?.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl isRequired>
              {frequencyType === 'MONTHLY' || frequencyType === 'WEEKLY' ? (
                <>
                  <FormLabel>Day of {frequencyType === 'MONTHLY' ? 'Month' : 'Week'}</FormLabel>
                  {frequencyType === 'WEEKLY' && (
                    <Select
                      defaultValue={displayFrequencyDayValue}
                      onChange={(v) => {
                        setFrequencyDay(v.target.value === '' ? undefined : Number(v.target.value));
                      }}
                    >
                      <option value="">Select a day</option>
                      {dayOfWeekOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  )}
                  {frequencyType === 'MONTHLY' && (
                    <Select
                      defaultValue={displayFrequencyDayValue}
                      onChange={(v) => {
                        setFrequencyDay(v.target.value === '' ? undefined : Number(v.target.value));
                      }}
                    >
                      <option value="">Select a day</option>
                      {dayOfMonthOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  )}
                </>
              ) : (
                <Box />
              )}
            </FormControl>
          </SimpleGrid>
          <FormControl>
            <FormLabel pt="25px">Notes</FormLabel>
            <Textarea h="150px" placeholder="Note" defaultValue={note} onChange={(v) => setNote(v.target.value)} />
          </FormControl>
          <Box w="100%" pt="15px">
            <FormControl display="flex" alignItems="center">
              <FormLabel margin="unset">Enable automated export?</FormLabel>
              <Switch
                ml="15px"
                mr="15px"
                id="automated-export"
                isChecked={automatedExport}
                onChange={(v) => {
                  setAutomatedExport(v.target.checked);
                }}
              />
              {isPaused ? convertToLocalDayAndTime(exportConfig?.pausedAt) : null}
            </FormControl>
          </Box>
          <FormControl isInvalid={error.length > 0}>
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
          <Card colorScheme="cyan" variant="filled" mt="15px" mb="15px">
            <CardBody>
              <Flex direction="row">
                <Center>
                  <InfoIcon boxSize={5} aria-label="Table Alias Info" />
                </Center>
                <Flex direction="column">
                  <Text pl="15px">
                    If a file name is not specified, then the data will be broken out by program into separate folders
                    by each program name. If a file name is specified, then the organization data will be aggregated
                    into a file with that name.
                  </Text>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleClose}>
            Close
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              save();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddEditExportModal;
